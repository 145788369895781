import http from "../httpService";
import { json_facet } from "./AdvancedSeach.api";

export const fq__resource_type = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 )";
export const staticData = {
  ...json_facet,
  authors_tk: { type: "terms", field: "authors_tk", limit: 100 },
  primary_publisher_country: {
    type: "terms",
    field: "primary_publisher_country",
    limit: 100,
  },
  filter_metrix: { type: "terms", field: "filter_metrix", limit: 100 },
  journal_name: { type: "terms", field: "journal_name", limit: 100 },
  guide_name_tk: { type: "terms", field: "guide_name_tk", limit: 100 },
  speakers: { type: "terms", field: "speakers", limit: 100 },
  researcher_tk:{type:"terms",field:"researcher_tk",limit:100},
  openaccess_type: {
    type: "terms",
    field: "openaccess_type",
    limit: 100,
  },
  author_address: { type: "terms", field: "author_address", limit: 100 },
  author_country_name: {
    type: "terms",
    field: "author_country_name",
    limit: 100,
  },
  is_retracted: {
    type: "terms",
    field: "is_retracted",
    limit: 100,
  },
  has_datasets: {
    type: "terms",
    field: "has_datasets",
    limit: 100,
  },
  pubmed_article: {
    type: "terms",
    field: "pubmed_article",
    limit: 100,
  },
  is_onos_resource: {
    type: "terms",
    field: "is_onos_resource",
    limit: 100,
  }
};

export async function basicSearch(query: any) {
  const myLibraryFilter = sessionStorage.getItem("my_library_filter");
  const consortiaFilter = sessionStorage.getItem("consortia_filter");
  const facetData = {...staticData}

  if(myLibraryFilter) {
    Object.assign(facetData, {Collections_LibrarySubscribed : {type: "query", q: myLibraryFilter}});
  }
  if(consortiaFilter) {
    Object.assign(facetData, {Collections_ConsotiaSubscribed : {type: "query", q: consortiaFilter}});
  }
  try {
    const { data: response } = await http.get(
      `/api/indexing/solr/articlecore2?rows=20&fq__active_flag=true&json_facet=${JSON.stringify(
        facetData
      )}&${query}`
    );

    return response.data;
  } catch (error) {
    console.log("Couldnt Fetch Search Results: ", error);
    throw error;
  }
}

//No Default Resource Type
export async function basicSearchMain(query: any) {
  const myLibraryFilter = sessionStorage.getItem("my_library_filter");
  const consortiaFilter = sessionStorage.getItem("consortia_filter");
  const facetData = {...staticData}

  if(myLibraryFilter) {
    Object.assign(facetData, {Collections_LibrarySubscribed : {type: "query", q: myLibraryFilter}});
  }

  if(consortiaFilter) {
    Object.assign(facetData, {Collections_ConsotiaSubscribed : {type: "query", q: consortiaFilter}});
  }
  try {
    const { data: response } = await http.get(
      `/api/indexing/solr/articlecore2?rows=20&fq__active_flag=true&json_facet=${JSON.stringify(
        facetData
      )}&${query}`
    );

    return response.data;
  } catch (error) {
    console.log("Couldnt Fetch Search Results: ", error);
    throw error;
  }
}

export async function basicSearchForCounts(query: any) {
  try {
    const { data: response } = await http.get(
      `/api/indexing/solr/articlecore2?rows=20&fq__active_flag=true&${query}`
    );

    return response.data;
  } catch (error) {
    console.log("Couldnt Fetch Search Count: ", error);
    throw error;
  }
}

export async function getAbstractStatus(query: string) {
  try {
    const { data: response } = await http.get(
      `/api/article_abstract_display_status/article_abstract_display_status${query}`
    );

    return response.data;
  } catch (error) {
    console.log("Couldnt Fetch Abstract Status: ", error);
    throw error;
  }
}

export async function basicSearchAuthorEmail(customerId: any) {
  try {
    const { data: response } = await http.get(
      `/api/get_authoremail_id/get_authoremail_id?article_id=${customerId}`
    );

    return response.data;
  } catch (error) {
    console.log("Couldnt Fetch Author email data: ", error);
    throw error;
  }
}