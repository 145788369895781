import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Tooltip } from "@mui/material";

const DownloadButton = (props: any) => {
  const { setGridDisplay, GridDisplay, resource_id, setGridId, showGrid } =
    props;
  return (
    <Tooltip title={"Open access"}>
      <LockOpenIcon
        color="secondary"
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </Tooltip>
  );
};

export default DownloadButton;

const webStyles = {};
