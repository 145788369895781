import { Grid, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CollectionsComponent from "../../components/leftBarComponent/collections";
import PublicationDate from "../../components/leftBarComponent/publicationComponent";
import SearchFilter from "../../components/leftBarComponent/searchFilter";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearLimits,
  clearPublicationFilter,
  updataLibraryOpac,
  updateCollectionValue,
  updateConsortiaCollection,
  updateJgateCollection,
  updateMyLibraryCollection,
  updateOnosCollection,
  updatePersonalLibrary,
} from "../../store/slice/filterSlice";
import { onSearch as search } from "../../screens/Search/onSearch";
import { updateFullText } from "../../store/slice/searchSlice";
import { setConstantValue } from "typescript";
import dayjs from "dayjs";
import { usagelogApi } from "../../api/usageReportApi";
import { updateQueries } from "../../store/slice/appliedQueries";

const Filters = ({ searchResults, resultCount, allCount }: any) => {
  const dispatch = useAppDispatch();
  const selectedCollection = useAppSelector(
    (state) => state.filterReducer.collectionValue
  );

  const [publicationValue, setPublicationValue] = useState("");
  const [collectionsValue, setcollectionsValue] = useState(selectedCollection);

  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState<string>("");

  const isOpac = useAppSelector(
    (state: any) => state.filterReducer.libraryOpac
  );
  const filterReducer = useAppSelector((state: any) => state.filterReducer);
  const searchReducer = useAppSelector((state: any) => state.searchReducer);
  console.log("srirams",searchReducer);
  const onlyBooks = searchReducer?.dataType?.length === 1 && searchReducer?.dataType?.includes(12) ; 
  const profileData = useAppSelector((state: any) => state.login);
  const customerDetails = useAppSelector(
    (state: any) => state.customer.customerDetails
  );
  const profileData1 = useAppSelector(
    (state: any) => state.customer.customerDetails
  );

  const DateObj = {
    currentYear: dayjs().year(),
    month: dayjs().month() + 1,
    date: dayjs().date(),
  };

  useEffect(() => {
    clearCollection();
  }, [selectedCollection]);

  useEffect(() => {
    updateCollectionValues();
  }, [
    filterReducer.jgateCollection,
    filterReducer.consortiaCollection,
    filterReducer.myLibraryCollection,
    filterReducer.onosCollection,
    filterReducer.libraryOpac,
    filterReducer.myPersonalLibCollection,
  ]);

  useEffect(() => {
    updatePublication();
  }, [
    filterReducer.earlyOnline,
    filterReducer.dateFrom,
    filterReducer.monthFrom,
    filterReducer.yearFrom,
  ]);

  const selectedFilters = useAppSelector((state) => state.filterReducer);

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  function clearAllLimits() {
    logBasicSearchUsageData(61);
    dispatch(clearLimits());
    dispatch(clearPublicationFilter());
    setPublicationValue("");
    dispatch(search());
    setFromValue("");
    setToValue("");
    dispatch(updateFullText(false)); // Made it false so on clear && fullText==0, it will stay on All Tab
    if (
      customerDetails?.product_type === "1" ||
      customerDetails?.product_type === "1,7"
    ) {
      setcollectionsValue("J-GateCollection");
      dispatch(updateCollectionValue("J-GateCollection"));
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateConsortiaCollection(false));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
      dispatch(updateJgateCollection(true));
    } else if (customerDetails?.product_type === "7") {
      setcollectionsValue("ConsortiaSubscriptions");
      dispatch(updateCollectionValue("ConsortiaSubscriptions"));
      dispatch(updateMyLibraryCollection(false));
      dispatch(updateJgateCollection(false));
      dispatch(updateConsortiaCollection(true));
      dispatch(updateOnosCollection(false));
      dispatch(updatePersonalLibrary(false));
      dispatch(updataLibraryOpac(false));
    }
  }

  function clearCollection() {
    if (!selectedCollection) setcollectionsValue("");
  }

  function updateCollectionValues() {
    setcollectionsValue("");
    if (filterReducer.jgateCollection) {
      setcollectionsValue("J-GateCollection");
      dispatch(updateCollectionValue("J-GateCollection"));
    }
    if (filterReducer.consortiaCollection) {
      setcollectionsValue("ConsortiaSubscriptions");
      dispatch(updateCollectionValue("ConsortiaSubscriptions"));
    }
    if (filterReducer.myLibraryCollection) {
      setcollectionsValue("MyLibraryCollection");
    }
    if (filterReducer.onosCollection) {
      setcollectionsValue("ONOSCollections");
    }
    if (filterReducer.libraryOpac) {
      setcollectionsValue("Library OPAC");
    }
    if (filterReducer.myPersonalLibCollection) {
      setcollectionsValue("MyPersonalLibraryCollection");
    }
  }

  function updatePublication() {
    let condition = "TO";
    let currentYear = `[${DateObj.currentYear} ${condition} ${DateObj.currentYear}]`;
    setPublicationValue("");
    if (filterReducer.earlyOnline) {
      setPublicationValue("EarlyOnline");
    }
    if (filterReducer.dateFrom) {
      setPublicationValue("LastOneWeek");
    }
    if (filterReducer.monthFrom) {
      setPublicationValue("LastOneMonth");
    }
    if (filterReducer.yearFrom.toString() === currentYear.toString()) {
      setPublicationValue("CurrentYear");
    }
    if (!filterReducer.yearFrom) {
      setFromValue("");
      setToValue("");
    }
  }

  return (
    <Grid item xs={2.5}>
      {/* LEFT COMPONENT */}
      <Box sx={{ paddingBlock: 2 }}>
        <Typography fontWeight={"700"} fontSize={"20px"} lineHeight={"24px"}>
          Results{" "}
          {resultCount
            ? resultCount?.toLocaleString("en-US")
            : searchResults?.hits?.toLocaleString("en-US")}
        </Typography>
      </Box>
      <PublicationDate
        publicationValue={publicationValue}
        setPublicationValue={setPublicationValue}
        clearAllLimits={clearAllLimits}
        isOpac={isOpac}
        fromValue={fromValue}
        setFromValue={setFromValue}
        toValue={toValue}
        setToValue={setToValue}
        onlyBooks={onlyBooks}
      />

      <CollectionsComponent
        jsonFacets={searchResults?.jsonfacets}
        data={searchResults}
        collectionsValue={collectionsValue}
        setcollectionsValue={setcollectionsValue}
        allCount={allCount}
      />

      {!isOpac && (
        <SearchFilter
          yearFromData={searchResults?.jsonfacets?.yearfrom?.buckets}
          jsonFacets={searchResults?.jsonfacets}
        />
      )}
    </Grid>
  );
};

export default Filters;
