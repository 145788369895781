import { AppThunk } from "../../store";
import { removeAllFilterType } from "../../store/slice/filterSlice";

type Key =
  | "sourceType"
  | "subject"
  | "author"
  | "journal"
  | "speaker"
  | "research"
  | "journalRank"
  | "countryOfPublication"
  | "countryOfPublishingAuthor"
  | "publisher"
  | "institutions"
  | "yearFrom"
  | "conference_name"
  | "university_name"
  | "researcher";

export const filterUpdate =
  (filterArr: any, key: Key): AppThunk<any> =>
  async (dispatch, getState) => {
    const filter = getState().filterReducer[key];
    if (!Array.isArray(filterArr)) return;
    if (filterArr.length) {
      for (let i = 0; i < filterArr.length; i++) {
        if (!filter.includes(filterArr[i].value))
          dispatch(removeAllFilterType(filterArr[i].value));
      }
    }
  };
