import { useEffect, useState } from "react";
import {
  Typography,
  Select,
  MenuItem,
  Divider,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import api from "../../api/endpoints";
import { usagelogApi } from "../../api/usageReportApi";
import { useAppSelector } from "../../store/hooks";

const CiteArticle = ({
  openViewModal,
  handleCloseViewModal,
  dialogueData,
  citeDetailsDropDown,
  articleApiDetails,
  setCitation,
  citation,
  handleChange,
  data,
  composeData,
  resultFrom,
}: any) => {
  const [downloadType, setDownloadType] = useState("Bib text file");
  const profileData = useAppSelector((state) => state.login);
  const [isCopy, setIsCopy] = useState("Copy");
  const location = useLocation();
  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state?.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state?.browseFilterSlice
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder?.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch?.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logCiteArticleUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;

    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };
  const handleExport = async () => {
    let id;
    if (downloadType === "Bib text file") {
      id = 1;
    } else if (
      downloadType === "Ref Works" ||
      downloadType === "EndNote" ||
      downloadType === "Mendeley" ||
      downloadType === "ProCite" ||
      downloadType === "Reference Manager"
    ) {
      id = 2;
    } else if (downloadType === "PDF") {
      id = 3;
    } else if (downloadType === "Doxc") {
      id = 4;
    }

    try {
      let apiJournalData: any = {
        loggedin_customer_id: sessionStorage.getItem("user_id"),
        download_type: id,
        selected_type: 3,
        article_ids: data?.article_id,
      };
      let obj = JSON.stringify(composeData);

      const apiParams = objectToQueryString(apiJournalData);
      // const win: Window | null = window.open(
      //   `${api.baseApi}/api/ArticleMetadataDownload/ArticleMetadataDownload?${apiParams}`
      // );
      // if (win) {
      //   win.onload = () => {
      //     win.print();
      //   };
      // }

      // downloadFile(
      //   `${api.baseApi}/api/ArticleMetadataDownload/ArticleMetadataDownload?${apiParams}`,
      //   apiParams
      // );

      downloadURI(
        `${api.baseApi}/api/ArticleMetadataDownload/ArticleMetadataDownload?article_details=${obj}&${apiParams}`,
        "filename"
      );

      logCiteArticleUsageData(97, dialogueData);
    } catch (err) {
      console.log("error", err);
    }
  };
  function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  }

  async function copyTextToClipboard(text: string) {
    let x;
    let str = Array.isArray(text) ? "" : text;
    if (location.pathname === "/marked-result" && !Array.isArray(text)) {
      // @ts-ignore
      let joinedData = str.join(" ");
      x = joinedData.replace(/<[^>]*>/g, "");
    } else {
      x = str.replace(/<[^>]*>/g, "");
    }
    if (Array.isArray(text)) {
      x = text.map((obj) => obj?.cite_result);
    }
    setIsCopy("Copied");
    logCiteArticleUsageData(97, dialogueData);

    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(x);
    } else {
      const el = document.createElement("textarea");
      el.value = x;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      el.focus();
      document.execCommand("copy");
      document.body.removeChild(el);
    }
  }

  useEffect(() => {
    return () => {
      setIsCopy("Copy");
    };
  }, [openViewModal]);
  return (
    <>
      <Dialog
        open={openViewModal}
        onClose={() => {
          setIsCopy("Copy");
          handleCloseViewModal();
        }}
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            textAlign="left"
            fontFamily={"Lora"}
            fontWeight={700}
            fontSize={28}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Cite this Item/Articles
          </Typography>
          <IconButton onClick={handleCloseViewModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography
            textAlign="left"
            fontWeight={500}
            fontSize={16}
            id="modal-modal-description"
            sx={{ mt: 2, color: "#212121" }}
          >
            Copy Citation
          </Typography>

          <Select
            // labelId="demo-multiple-name-label"
            // id="demo-multiple-name"
            // multiple
            value={citation}
            onChange={(e) => {
              handleChange(e);
              setIsCopy("Copy");
            }}
            sx={{ width: "551px", background: "#fffff" }}
          >
            {citeDetailsDropDown?.map((e: any) => {
              let str = e?.cite_type.split("-");
              let element = e?.cite_type;
              if (str.length === 1) {
                element = toTitleCase(e?.cite_type);
              }

              return <MenuItem value={e?.cite_type}>{element}</MenuItem>;
            })}
          </Select>

          <Box
            style={{
              borderStyle: "solid",
              borderWidth: "thin",
              marginTop: "2%",
              maxHeight: 300,
              overflow: "auto",
            }}
          >
            {Array.isArray(articleApiDetails) ? (
              articleApiDetails.map((obj) => (
                <Typography sx={{ padding: "1%" }}>
                  <div
                    dangerouslySetInnerHTML={{ __html: obj?.cite_result }}
                    style={{
                      background: isCopy === "Copied" ? "#03fcec" : "",
                    }}
                  ></div>
                </Typography>
              ))
            ) : (
              <Typography sx={{ padding: "1%" }}>
                <div
                  dangerouslySetInnerHTML={{ __html: articleApiDetails }}
                  style={{
                    background: isCopy === "Copied" ? "#03fcec" : "",
                  }}
                ></div>
              </Typography>
            )}
          </Box>

          <Button
            onClick={() => copyTextToClipboard(articleApiDetails)}
            sx={{
              "&:hover": {
                backgroundColor: "#7852FB",
              },

              textAlign: "left",
              backgroundColor: "#7852FB",
              color: " #FAF8F5",
              mt: 2,
              textTransform: "capitalize",
              borderRadius: "8px",
              fontSize: "16px",
              width: "130px",
              height: "51px",
            }}
          >
            {isCopy}
          </Button>

          <Divider
            light
            sx={{ background: " #E5E7EB", width: "551px", mt: 2 }}
          />

          <Typography
            textAlign="left"
            fontWeight={500}
            fontSize={16}
            id="modal-modal-description"
            sx={{ mt: 2, color: "#212121" }}
          >
            Export to Citation Manager or File
          </Typography>

          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={downloadType}
            sx={{ width: "551px", background: "#fffff" }}
            onChange={(event: any) => setDownloadType(event.target.value)}
          >
            <MenuItem value={"Bib text file"}>Bib text file</MenuItem>
            {/* <MenuItem value={"Ris text file"}>Ris text file</MenuItem> */}
            <MenuItem value={"EndNote"}>EndNote (RIS)</MenuItem>
            <MenuItem value={"Mendeley"}>Mendeley (RIS)</MenuItem>
            <MenuItem value={"ProCite"}>ProCite (RIS)</MenuItem>
            <MenuItem value={"Reference Manager"}>
              Reference Manager (RIS)
            </MenuItem>
            <MenuItem value={"Ref Works"}> Ref Works (RIS)</MenuItem>

            <MenuItem value={"PDF"}>PDF</MenuItem>
            <MenuItem value={"Doxc"}>DOCX</MenuItem>
          </Select>

          <DialogActions
            style={{ justifyContent: "flex-start" }}
          ></DialogActions>

          <Button
            // onClick={() => downloadDocument(citation)}
            onClick={handleExport}
            // onClick={() => console.log("citation", citation)}
            sx={{
              "&:hover": {
                backgroundColor: "#7852FB",
              },
              textAlign: "left",
              backgroundColor: "#7852FB",
              color: " #FAF8F5",
              textTransform: "capitalize",
              borderRadius: "8px",
              fontSize: "16px",
              width: "130px",
              height: "51px",
            }}
          >
            {/* Export */}
            Download
          </Button>
          <Button onClick={handleCloseViewModal} sx={{ color: Colors.black }}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CiteArticle;
