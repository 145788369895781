import * as React from "react";
import Tabs from "@mui/material/Tabs";
// import { Tab } from 'react-tabs';
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Colors } from "../utils/constants";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const FAQDetails = (props: any) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleFeedbacklink = () => {
    props.setTabIndex("4");
  };

  return (
    <>
      <Box marginLeft={"50px"} marginTop={"40px"}>
        <Typography fontWeight={700} fontSize={28} sx={textStyle.headercolor}>
          FAQ: Frequently Asked Questions
        </Typography>

        <Typography sx={textStyle.subText}>
          J-Gate help desk received a number of queries frequently asked by
          customers. As a service to other customers who may have similar
          questions, answers to these FAQs are provided below.
        </Typography>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          // height: "380px",
          // height: "43%"
        }}
      >
        <Tabs
          orientation="vertical"
          centered={false}
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{
            ".MuiTabs-indicator": {
              left: 0,
            },
            borderLeft: 1,
            borderColor: "divider",
            alignItems: " self-start",
            justifyContent: " flex-start",
            // width: "514px",
            width: "32%",
            marginLeft: "2%",
            marginTop: "2%",
            // marginLeft: "70px"
          }}
        >
          <Tab
            sx={textStyle.tabs}
            label="General Information"
            {...a11yProps(0)}
          />
          <Tab
            sx={textStyle.tabs}
            label="J-Gate Coverage Statistics"
            {...a11yProps(1)}
          />
          <Tab
            sx={textStyle.tabs}
            label="Features & Functionalities & Access related"
            {...a11yProps(2)}
          />
          <Tab sx={textStyle.tabs} label="Subscription" {...a11yProps(3)} />
          <Tab
            sx={textStyle.tabs}
            label="Technical Questions"
            {...a11yProps(4)}
          />
          <Tab sx={textStyle.tabs} label="For Publishers" {...a11yProps(5)} />
          <Tab sx={textStyle.tabs} label="For Librarians" {...a11yProps(6)} />
          <Tab
            sx={textStyle.tabs}
            label="Frequently Asked Questions"
            {...a11yProps(7)}
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            // width={"680px"}
            width={"45%"}
          >
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              General Information
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              What is J-Gate?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate functions as a electronic gateway access to a vast array of
              e-journal content worldwide. Initially introduced in 2001 by
              Informatics India Limited, this platform grants users seamless
              entry to millions of online journal articles sourced from 15,294
              publishers. Presently, J-Gate boasts an extensive database
              comprising literature from 67,487 periodical publications
              e-journals, Conference-Proceedings, Book series) each with direct
              links to full-text articles hosted on publisher websites.
              Additionally, J-Gate aims to provide supplementary services
              including online journal subscription, electronic document
              delivery, archival facilities, and more. Key features of J-Gate
              include:
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              <ol
                style={{ marginLeft: "50px" }}
                // style={{
                //   listStyle: "none",
                //   color: Colors.coolGray700,
                //   padding: "0px",
                // }}
              >
                <li>
                  Enhanced Content Coverage: Access a wider range of content
                  than ever before, ensuring you have all the information you
                  need at your fingertips.
                </li>
                <li>
                  Table of Contents (TOC) - Covering 67, 487 periodical
                  publications.
                </li>
                <li>
                  Database - Offering a comprehensive searchable database
                  housing a staggering 83,565,438 articles, with over 40,000 new
                  articles incorporated daily.
                </li>
                <li>
                  New Search & Browse Experience: Our latest technology provides
                  a seamless and intuitive search and browse experience, making
                  it easier than ever to find what you're looking for.
                </li>
                <li>
                  More Full text Coverage: Dive deeper into content with
                  expanded full-text coverage, allowing you to explore topics
                  comprehensively.
                </li>
                <li>
                  Mobile Applications (Android & iOS): Take your experience on
                  the go with our mobile applications, available for both
                  Android and iOS devices. Access your favorite features
                  anytime, anywhere.
                </li>
              </ol>
            </Typography>
            <Typography width={850} sx={textStyle.headingText}></Typography>

            <Typography sx={textStyle.headingText}>
              Who is the intended audience for J-Gate?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Libraries, Students and faculties of Colleges and universities,
              scientists, engineers, practitioners and researchers of R&D,
              industries, corporate etc use J-Gate for their educational and
              research needs and to know the latest developments in their
              respective area of interest.
            </Typography>
            <Typography sx={textStyle.headingText}>
              How often is the J-Gate database updated?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate database is updated on a daily basis to ensure that you
              have immediate access to the latest content.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How latest is the content on J-Gate when compared to publisher's
              website?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              There is zero to two day lag in content when compared to
              publisher's website.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              What types of journals are included in J-Gate?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate primarily indexes journals which are scientific and
              scholarly, it also includes professional & industry journals.
              Journals indexed in J-Gate should have full text articles and
              there is no subject restriction for inclusion. J-Gate covers
              journals published in English or at least Table of Contents and
              abstracts are in English. J-Gate indexes both open access and non
              open access journals.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How does J-Gate mark a journal as Peer Reviewed?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate flags a journal as peer reviewed if only the same is
              indicated by the publishers on their websites
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              What are the types of Open Access Journals included in J-Gate?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Currently J-Gate marks only those journals as open access which is
              free. It also includes those Open Access journals which require
              online registration.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Are the journals subject classified using Library of Congress or
              Dewey Decimal classification or any standards subject headings?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              No, J-Gate uses a proprietary subject classification system to
              classify journals.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Whom do I contact to get a journal included in J-Gate?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              To get your journal indexed in J-Gate, please send an email with
              the name of the journal and its URL to
              <a
                href="mailto:jcoordinator@informaticsglobal.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  margin: "5px",
                  color: "#7852fb",
                  cursor: "pointer",
                }}
              >
                jcoordinator@informaticsglobal.com{" "}
              </a>
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Where do I get user instructions/help?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              <ol type="i" style={{ marginLeft: "50px" }}>
                <li>
                  Context sensitive online help: The help button is located on
                  the top navigation bar on all screens of J-Gate .
                </li>
                <li>
                  Online help facility: By clicking 'On Line Support' button
                  provided on the bottom navigation bar, you will get help from
                  one of our technical staff to resolve your queries.
                </li>
                <li>
                  If you require further technical assistance please contact
                  <a
                    href="mailto:jcoordinator@informaticsglobal.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      margin: "5px",
                      color: "#7852fb",
                      cursor: "pointer",
                    }}
                  >
                    jcoordinator@informaticsglobal.com{" "}
                  </a>
                </li>
              </ol>
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How can I request for Trial access?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Please send an email to{" "}
              <a
                href="mailto:jcoordinator@informaticsglobal.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  margin: "5px",
                  color: "#7852fb",
                  cursor: "pointer",
                }}
              >
                jcoordinator@informaticsglobal.com{" "}
              </a>
              with your Name, Institution/Organization Name & address along with
              your email id
            </Typography>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Typography fontWeight={700} fontSize={28} sx={textStyle.headercolor}>
            J-Gate Coverage Statistics
          </Typography>
          <Box
            alignItems={"center"}
            justifyContent={"center"}
            // width={"680px"}
            width={"45%"}
          >
            <Typography width={850} sx={textStyle.headingText}>
              How many journals are there in the portal?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Presently J-Gate indexes 67,487 journals
            </Typography>

            <Typography width={850} sx={textStyle.headingText}>
              How many are peer reviewed journals?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Out of 67,487 journals indexed 66,195 are peer reviewed journals
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How many Open Access Journals are there in J-Gate?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              35,479 Open Access Journals are indexed in J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How many Open access Peer Reviewed Journals are in there in
              J-Gate?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              34,689 Open access Peer Reviewed Journals are in there in J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How many Indian journals?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              5,950 Indian journals are indexed in J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How many Indian peer reviewed journals?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              5,829 Indian peer reviewed journals are in J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How many publishers are indexed in J-Gate?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              15,299 publishers are indexed in J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How many articles are indexed in J-Gate?{" "}
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              83,612,388 articles are covered in J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How many open access articles are indexed in J-Gate?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              24,658,198 Open access articles are indexed in J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Does J-Gate indexes content other than journals?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Yes, J-Gate primarily indexes scholarly journals, but it also
              includes other types of academic content such as conference
              proceedings, dissertations, theses, A-V lectures, Magazines and
              preprints. This comprehensive coverage allows researchers to
              access a wide range of academic literature across various
              disciplines.
            </Typography>
          </Box>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Typography fontWeight={700} fontSize={28} sx={textStyle.headercolor}>
            Features & Functionalities & Access related
          </Typography>

          <Typography width={850} sx={textStyle.headingText}>
            What features are available in J-Gate?
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            The following features are available in J-Gate
            <ol type="a" style={{ marginLeft: "50px" }}>
              <li>Basic search</li>
              <li>Search Builder</li>
              <li>Journal finder </li>
              <li>Author finder </li>
              <li>Alert services</li>
              <li>Marked Results </li>
              <li>Rights & access management system</li>
              <li>Provision to filtering search results</li>
            </ol>
          </Typography>

          <Typography width={850} sx={textStyle.headingText}>
            How to register for getting regular Alerts?{" "}
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            Click on the 'Alerts' button provided against journal name at the
            article level or at the journal in Journal finder or user can click
            on the top navigation bar and register by giving your name &
            email_id. After registration, please select the journals for which
            you are interested in getting alerts and save the profile. Upon
            saving your profile, you will get regular alerts.
            <br />
            <br />
            <Typography width={850} sx={textStyle.subText}>
              Use Search history to set the alert for most searched topic
              pattern
            </Typography>
          </Typography>
          <Typography width={850} sx={textStyle.headingText}>
            Can I email articles using J-Gate?{" "}
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            Yes, you can email the articles retrieved and selected from J-Gate.
          </Typography>
          <Typography width={850} sx={textStyle.headingText}>
            Can I download articles from the J-Gate site?{" "}
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            Yes, you can download articles from J-Gate from all open access
            journals and the journals subscribed by your library.
          </Typography>
          <Typography width={850} sx={textStyle.headingText}>
            How to search articles? What is the difference between Basic Search
            & Search Builder
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            To search articles, J-Gate Currently provides both Basic Search and
            Search Builder modes
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            <ol type="i" style={{ marginLeft: "20px" }}>
              <li>
                Basic Search, meant for starters/novices, can be used by typing
                in search box or by picking autosuggest keywords. These keywords
                are searched in Title, Abstract, and Keywords of the articles.
                You can do Boolean search or exact phrase search and also limit
                your search results for the following parameters
                <ol type="a" style={{ marginLeft: "50px" }}>
                  <li>Subjects</li>
                  <li>Document types</li>
                  <li>Collections</li>
                  <li>Publication Dates</li>
                </ol>
              </li>
              <li>
                In Search Builder, meant for by experienced users. You can do
                lot of combination searches and restrict your search to any of
                the parameters. You can use the query builder editing box to
                modify the preferences for the given search terms
                <ol type="a" style={{ marginLeft: "50px" }}>
                  <li>
                    Pick and choose the field(s) provided in the dropdown menu
                    like Article Title, Author, Author affiliation,
                    Title/Keywords/Abstract, Title/Keywords etc.
                  </li>
                  <li>Perform Boolean searches</li>
                  <li>Perform exact phrase search</li>
                  <li>Nested logic search</li>
                  <li>
                    You can limit your search results within a wide range like
                  </li>
                  <ol type="i" style={{ marginLeft: "65px" }}>
                    <li>Subjects</li>
                    <li>Document Types</li>
                    <li>Publication year</li>
                    <li>Pick from journal list</li>
                  </ol>
                </ol>
              </li>
            </ol>
          </Typography>
          <Typography width={850} sx={textStyle.headingText}>
            How to find author of my choice?
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            <ol type="1" style={{ marginLeft: "20px" }}>
              <li>Look for the "Author Finder" from Menu bar.</li>
              <li>
                Once you're in the Author Finder tool, you can typically search
                for authors by their first and last name.
              </li>
              <li>
                Enter the name of the author you're looking for into the search
                bar and click on the search button.
              </li>
              <li>
                Review the search results to find the specific author you're
                interested in. select the required authors from the listing and
                click on View Results at the top.
              </li>
            </ol>
          </Typography>
          <Typography width={850} sx={textStyle.headingText}>
            How to access full text articles which are not subscribed by my
            institution but available in the portal?{" "}
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            To access the full text articles which are not subscribed by your
            institution, you can either send an email to the author using the
            author email id provided or you can write to one of the institutions
            listed against the article under 'Find-in-a-Library'.
          </Typography>
          <Typography width={850} sx={textStyle.headingText}>
            Can I access the table of contents for all the journals subscribed
            by my library{" "}
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            Yes, you can access the table of contents for all the journals
            subscribed by your library through J-Gate.
          </Typography>
          <Typography width={850} sx={textStyle.headingText}>
            I have access to J-Gate portal, but not able to access fulltext at
            publisher site
          </Typography>
          <Typography width={850} sx={textStyle.subText}>
            Please contact{" "}
            <a
              href="mailto:jcoordinator@informaticsglobal.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                margin: "5px",
                color: "#7852fb",
                cursor: "pointer",
              }}
            >
              jcoordinator@informaticsglobal.com{" "}
            </a>
          </Typography>

          {/* <Typography sx={textStyle.headingText}>J-Gate Database</Typography>
          <Typography width={726} sx={textStyle.subText}>
            <ol style={{ marginLeft: "20px" }}>
              <li>
                Provides an extensive range of content encompassing diverse
                document types
              </li>
              <li>Classifies subjects into three levels.</li>
              <li>Provides subject-wise indexing.</li>
              <li>
                Searchable by Title, Author, Subject Categories, Keywords, Year,
                Journal, Institutions, DOI etc.
              </li>
              <li>
                Retrieve the ORCID information of the author and access further
                details about the author from their ORCID profile
              </li>
              <li>Includes basic bibliographic data with abstracts.</li>
              <li>Author addresses and emails provided where available.</li>
              <li>
                Direct links to full-text articles (both open-access and
                subscription).
              </li>
              <li>
                Union List link for availability checks in the form of “Find in
                Library”.
              </li>
              <li>
                Access to alternative full-text versions from preprint sources
                for articles behind paywalls
              </li>
              <li>
                Retrieve information on retracted articles for the obtained
                results.
              </li>
              <li>
                Retrieve information about articles mapping datasets for the
                obtained results.
              </li>
              <li>
                Implement a filter on the acquired results to specifically
                showcase early online articles, if they are accessible for the
                provided search term
              </li>
              <li>Daily updates with progressive accumulation.</li>
              <li>
                Browse and apply filters to the results to specifically view
                articles from journals indexed in Web of Science, Scopus, DOAJ
                etc
              </li>
              <li>Tailor the collection to your preferences by </li>
              <ol type="i" style={{ marginLeft: "50px" }}>
                <li>
                  configuring subscriptions under 'My Library Collections'
                </li>
                <li>
                  Personalize your collection by bookmarking your favorite
                  journals or searched documents into your personal library for
                  future search and browsing endeavors.
                </li>
              </ol>
            </ol>
          </Typography>
          <Typography sx={textStyle.headingText}>J-Gate@Consortia</Typography>
          <Typography width={726} sx={textStyle.subText}>
            <ol style={{ marginLeft: "20px" }}>
              <li>
                Serves as a search platform and resource-sharing hub for
                consortium members.
              </li>
              <li>
                Customized coverage restricted to Consortium and member library
                subscribed journals.
              </li>
              <li>
                Exclusive service tailored for registered closed-user-group
                members of the consortium.
              </li>
              <li>
                Designed for resource and infrastructure sharing among members
                through a mutual agreement.
              </li>
            </ol>
          </Typography> */}

          {/* <Box alignItems={"center"} justifyContent={"center"} width={"680px"}>
            {aboutUsDetails?.map((data, index) => (
              <Box key={index}>
                <Typography sx={textStyle.headingText}>{data.title}</Typography>
                {data.description?.map((item, i) => (
                  <ul key={i} style={{ padding: "0px" }}>
                    <li style={textStyle.subText}>{item}</li>
                  </ul>
                ))}
              </Box>
            ))}
          </Box> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Box alignItems={"center"} justifyContent={"center"} width={"45%"}>
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              Subscription
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Subscribing to J-Gate offers institutions a cost-effective way to
              access a wealth of scholarly content, enhance research
              capabilities, and support the academic and professional endeavors
              of their users
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              <ol style={{ marginLeft: "50px" }}>
                <li>
                  Comprehensive Access: Subscribing to J-Gate provides access to
                  millions of journal articles from a wide range of publishers,
                  covering various subject areas and disciplines.
                </li>
                <li>
                  Flexible Options: J-Gate offers flexible subscription options
                  tailored to the needs of different institutions, including
                  academic libraries, research organizations, and corporate
                  entities.
                </li>
                <li>
                  Value for Money: Subscribing to J-Gate offers excellent value
                  for money by providing access to a vast collection of
                  high-quality e-journal literature at a reasonable cost.
                </li>
                <li>
                  Customization: Institutions can customize their subscriptions
                  to J-Gate based on their specific requirements, including the
                  number of users, access levels, and content preferences.
                </li>
                <li>
                  Access to Latest Research: Subscribers benefit from regular
                  updates and additions to the J-Gate database, ensuring access
                  to the latest research and scholarly literature.
                </li>
                <li>
                  User Support: J-Gate provides dedicated customer support to
                  assist subscribers with any queries or technical issues they
                  may encounter, ensuring a smooth and hassle-free experience.
                </li>
                <li>
                  Usage Statistics: Subscribers have access to usage statistics
                  and analytics, allowing them to track usage patterns, measure
                  the impact of their subscriptions, and make informed decisions
                  about content acquisition.
                </li>
              </ol>
            </Typography>

            {/* <Typography width={726} sx={textStyle.subText}>
              J-Gate owes much of its success and robustness to its partnerships
              with scholarly, technical, and key publishers and third party
              aggregators. These collaborations, often established through
              mutual agreements, form a cornerstone of J-Gate's strength. Visit
              our page to explore our current publisher partners who actively
              contribute to J-Gate.
            </Typography>
            <br />
            <Typography sx={textStyle.subText}>
              <a
                href="https://jgateplus.com/search/footer-html/PublisherPartners.jsp"
                target="_blank"
                rel="noreferrer"
                style={{ marginRight: "5px", color: "#7852fb" }}
              >
                Click here
              </a>
              <Typography component="span" width={726} sx={textStyle.subText}>
                to see our current collaborating partners actively participating
                in J-Gate.
              </Typography>
            </Typography> */}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Box alignItems={"center"} justifyContent={"center"} width={"45%"}>
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              Technical Questions
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate addresses technical queries proactively and provide users
              with the necessary support and resources to ensure a positive user
              experience with J-Gate
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              <ol style={{ marginLeft: "50px" }}>
                <li>
                  Dedicated Technical Support: J-Gate provides dedicated
                  technical support to assist with any technical queries or
                  issues user may encounter. Encourage them to reach out to the
                  technical support team for assistance.
                </li>
                <li>
                  Online Help Resources: Direct users to online help resources,
                  such as user guides, FAQs, which may help user to resolve
                  common technical issues on their own.
                </li>
                <li>
                  Contact Information: Provide users with contact information
                  for the J-Gate technical support team, including email
                  addresses and phone numbers, so they can easily reach out for
                  assistance.
                </li>
                <li>
                  Response Time: Inform users about the typical response time
                  for technical support inquiries and assure them that their
                  concerns will be addressed promptly.
                </li>
                <li>
                  Problem Reporting: Encourage users to report any technical
                  issues they encounter with J-Gate, including error messages,
                  system glitches, or usability issues. Assure them that their
                  feedback is valuable for improving the platform.
                </li>
                <li>
                  Updates and Maintenance: Inform users about scheduled updates,
                  maintenance, or downtime periods for J-Gate, so they are aware
                  of any potential disruptions to service.
                </li>
                <li>
                  User Training: Offer user training sessions or workshops to
                  help users familiarize themselves with the technical aspects
                  of using J-Gate effectively, such as search techniques,
                  advanced features, and customization options.
                </li>
              </ol>
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Box alignItems={"center"} justifyContent={"center"} width={"45%"}>
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              For Publishers
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Highlight on the benefits and value proposition of partnering with
              J-Gate platform:
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              <ol style={{ marginLeft: "50px" }}>
                <li>
                  Increased Visibility: J-Gate offers publishers an opportunity
                  to increase the visibility of their journals and articles by
                  making them accessible to a wide audience of researchers,
                  academics, and institutions worldwide.
                </li>
                <li>
                  Global Reach: Partnering with J-Gate provides publishers with
                  access to a global audience, allowing them to reach
                  researchers and readers in regions they may not have
                  previously accessed.
                </li>
                <li>
                  Enhanced Discoverability: J-Gate's advanced search
                  functionalities and indexing capabilities ensure that
                  publishers' content is easily discoverable by users searching
                  for specific topics, authors, or keywords.
                </li>
                <li>
                  Access Options: J-Gate offers both open-access and
                  subscription-based models, allowing publishers to choose the
                  access model that best suits their needs and business goals.
                </li>
                <li>
                  Support Services: J-Gate provides publishers with dedicated
                  support services to assist with content ingestion, metadata
                  management, and other technical aspects of content delivery.
                </li>
                <li>
                  Collaborative Partnerships: J-Gate values collaborative
                  partnerships with publishers, working closely with them to
                  ensure that their content is presented effectively and meets
                  the needs of users.
                </li>
              </ol>
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Box alignItems={"center"} justifyContent={"center"} width={"45%"}>
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              For Librarians
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate acknowledges its significance as a comprehensive electronic
              gateway to global e-journal literature. Here are some key points
              which support your patrons' research needs effectively :
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              <ol style={{ marginLeft: "50px" }}>
                <li>
                  Extensive Coverage: J-Gate provides access to millions of
                  journal articles available online from various publishers,
                  covering a wide range of subject areas.
                </li>
                <li>
                  Seamless Access: Users can easily access and search for
                  specific articles, authors, or topics of interest using
                  J-Gate's advanced search functionalities.
                </li>
                <li>
                  Full-Text Access: J-Gate offers access to full-text articles
                  from both open-access and subscription-based journals /
                  articles, ensuring users have access to the content they need.
                </li>
                <li>
                  Content Updates: The platform is regularly updated to provide
                  users with the latest research and literature, with new
                  content added on a daily basis.
                </li>
                <li>
                  Customization Options: J-Gate allows users to save searches,
                  set up alerts for new content, and customize their
                  preferences, providing a personalized experience.
                </li>
                <li>
                  Support and Assistance: J-Gate offers customer support through
                  various channels, including email, phone, and online help
                  resources, to assist users with any queries or issues they may
                  encounter.
                </li>
                <li>
                  Feedback Mechanism: J-Gate welcomes feedback and suggestions
                  from users to continuously improve the platform, ensuring it
                  meets the needs of librarians and their patrons.{" "}
                  <span
                    style={{ color: "#7852fb", cursor: "pointer" }}
                    onClick={handleFeedbacklink}
                  >
                    Feedback Link
                  </span>
                </li>
              </ol>
            </Typography>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <Box alignItems={"center"} justifyContent={"center"} width={"45%"}>
            <Typography
              fontWeight={700}
              fontSize={28}
              sx={textStyle.headercolor}
            >
              Frequently Asked Questions
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              What is J-Gate?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate is an electronic gateway to global e-journal literature,
              providing seamless access to millions of articles available online
              from various publishers.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How can I access J-Gate?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              You can access J-Gate through our website by creating an account
              or accessing it through subscribing institutions.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              What content does J-Gate cover?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate covers a vast range of scholarly content from e-journals,
              conference papers, Book series, Theses, Pre-Prints, Audio-Visual
              lectures including articles, research papers, reviews, and more,
              across various subject areas.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Can I search for specific articles or topics on J-Gate?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Yes, J-Gate offers advanced search functionalities that allow
              users to search for specific articles, authors, keywords, or
              topics of interest.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Does J-Gate provide access to full-text articles?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Yes, J-Gate provides access to full-text articles from both
              open-access and subscription-based journals, depending on the
              availability and access rights. J-Gate also provides to
              alternative full-text versions from preprint sources for articles
              behind paywalls
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Is there a way to save my searches or set up alerts for new
              content?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Yes, J-Gate offers features to save searches and set up alerts for
              new content based on specified search criteria, ensuring you stay
              updated with the latest research.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              How frequently is the content on J-Gate updated?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              The content on J-Gate is regularly updated to ensure users have
              access to the most current research and literature. Updates
              typically occur daily.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Can I access J-Gate on mobile devices?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Yes, J-Gate is accessible on various mobile devices, allowing
              users to search and access content on-the-go.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              What support options are available if I encounter any issues?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              J-Gate provides customer support through various channels,
              including email, phone, and online help resources, to assist users
              with any queries or issues they may encounter.
            </Typography>
            <Typography width={850} sx={textStyle.headingText}>
              Is there a way to provide feedback or suggestions for improvement?
            </Typography>
            <Typography width={850} sx={textStyle.subText}>
              Yes, J-Gate welcomes feedback and suggestions from users to
              continuously improve the platform. Users can submit feedback
              through the website or contact customer support directly.
            </Typography>
            <br />
            <Typography width={850} sx={textStyle.subText}>
              These FAQs aim to address common queries and provide assistance to
              users navigating the J-Gate platform. If you have any further
              questions or require additional support, please don't hesitate to
              contact our help desk.
            </Typography>
          </Box>
        </TabPanel>
      </Box>
    </>
  );
};

export default FAQDetails;

const textStyle = {
  headingText: {
    fontWeight: 700,
    fontSize: "18px",
    marginTop: "18px",
  },

  subText: {
    fontWeight: 500,
    fontSize: "18px",
    marginTop: "2px",
    color: Colors.coolGray700,
    listStyle: "none",
  },

  tabs: {
    fontSize: "18px",
    fontWeight: 700,
    fontFamily: "Helvetica Neue",
    textAlign: "left",
    alignItems: "flex-start",
  },
  headercolor: {
    color: "#7852fb",
  },
};
