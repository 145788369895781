import { useCallback, useEffect, useState } from "react";
import { searchHistoryApi } from "../../api/searchHistoryapi";
import { searchHistoryStyles as sx } from "./SearchHistory.styles";
import { Stack } from "@mui/material";
import HistoryTable from "./HistoryTable";
import QueryBuilder from "./QueryBuilder";
import { useDispatch } from "react-redux";
import { clearAllSetSearch } from "../../store/slice/setSearch";
import { clearAllFilters, clearAllFiltersValue, clearLimits } from "../../store/slice/filterSlice";
import { clearAuthorSearchTerm, clearAllFilters as clearAllAuthorFilters } from "../../store/slice/authorFinderSlice";
import { resetAppliedQueries } from "../../store/slice/appliedQueries";
import { clearSearchTerm } from "../../store/slice/searchSlice";

export interface SearchHistory {
  search_id: number;
  search_type: any;
  search_url: string;
  SearchTerms: string;
  result_count: number;
  createddate: string;
  datalerttypeid: number;
  dat_emailnotificationfrequency_id: number;
  alert_topic: string;
  is_saved: boolean;
  search_topic: any;
  is_current: boolean;
  mouse_over_data: string;
  row_number: number;
  is_delete: boolean;
}

export const stringSanitizer = (term: string): string => {
  return term.replace(
    /(titleKeywordsAbs|keywords_fz|title_fz|resourcemaster_id|author_address_fz|issn|articledoi|author_all_fz):\("([^"]+)"\)/g,
    "$2"
  );
};

const SearchHistoryNew = () => {
  const [searchHistory, setSearchHistory] = useState<SearchHistory[]>([]);
  const dispatch = useDispatch();

  const fetchSearchHistory = useCallback(async () => {
    try {
      const _searchHistory: SearchHistory[] = await searchHistoryApi();
      setSearchHistory(_searchHistory ?? []);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchSearchHistory();
  }, [fetchSearchHistory]);

  useEffect(() => {
    dispatch(clearAllSetSearch());
    dispatch(clearAllFilters());
    dispatch(clearLimits())
    dispatch(clearAuthorSearchTerm())
    dispatch(clearAllAuthorFilters());
    dispatch(resetAppliedQueries());
    dispatch(clearAllFiltersValue())
    dispatch(clearSearchTerm())
  }, [dispatch]);

  return (
    <Stack sx={sx.root}>
      {/* TABLE */}
      <HistoryTable
        searchHistory={searchHistory}
        fetchSearchHistory={fetchSearchHistory}
      />

      {/* BUILDER */}
      <QueryBuilder searchHistory={searchHistory} />
    </Stack>
  );
};
export default SearchHistoryNew;
