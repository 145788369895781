import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import "./style.css";
import {
  Checkbox,
  Card as MuiCard,
  Typography,
  Tooltip as MuiTooltip,
} from "@mui/material";
import { Colors, dataTypes } from "../../utils/constants";
import { SelectChangeEvent } from "@mui/material/Select";
import { LoginCred } from "../../types/interface";

// Icons
import EmailIcon from "@mui/icons-material/MailOutline";
import FolderIcon from "@mui/icons-material/FolderOutlined";
import PrintIcon from "@mui/icons-material/PrintOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import Tooltip from "../tootTips/hoverOverToolTips";
import ComposeEmail from "../basicSearchTopBar/composeEmail";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CiteArticle from "../resultsPopups/CiteArticle";
import {
  getCiteArticleDetails,
  getCiteDescription,
} from "../../api/citeArticle";
import AddToFolder from "../resultsPopups/AddToFolder";
import BrokenLinkIcon from "../../assets/img/broken_image.png";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ReportIssue from "../resultsPopups/ReportIssue";
import { useDispatch, useSelector } from "react-redux";
import { ReducerObj } from "../../types/interface";
import { profileDetailsAPI } from "../../api/userApi";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { notify } from "../../utils/Notify";
import { useAppSelector } from "../../store/hooks";
import {
  isStringOfArray,
  showIsProfileToast,
  sortAuthor,
  sortAuthorDetail,
  sortByAuthorRole,
} from "../../utils/helper";
import { setModal } from "../../store/slice/modalSlice";
import { useLocation } from "react-router-dom";
import { usagelogApi } from "../../api/usageReportApi";
import api from "../../api/endpoints";

// Types
interface PropType {
  children: React.ReactNode;
  data?: any;
  getMarkedResult?: Function;
  markedArticlesid?: string[];
  folderId?: number | null;
  searchTerm: any;
  setJournalResults?: any;
  index?: number;
  resultFrom?: any;
}

const Card = (props: PropType) => {
  const {
    children,
    data,
    getMarkedResult,
    markedArticlesid,
    folderId,
    searchTerm,
    setJournalResults,
    index,
    resultFrom,
  } = props;
  const dispatch = useDispatch();

  const [emailModal, setEmailPopUp] = useState(false);
  const [cite, setCite] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);
  const [openViewModal, setOpenViewModal] = useState<Boolean>(false);
  const [articleApiDetails, setArticleApiDetails] = useState("");
  const [email, setEmail] = useState("");
  const [reciepientName, setReciepientName] = useState("");
  const [citeDetailsDropDown, setciteDetailsDropDown] = useState<any>([]);
  const [folder_id, setFolderID] = useState<any>(folderId);
  const [isProfileUser, setIsProfileUser] = useState(false);
  if (index == 1) {
    console.log("index", data?.article_id);
  }
  const searchReducer: any = useSelector(
    (state: ReducerObj) => state.searchReducer
  );
  const [citation, setCitation] = useState<string>(
    "APA - American Psychological Association"
  );

  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);

  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );
  const allowProfile = customerDetails?.allow_profile;

  const savedArticles = useAppSelector(
    (state) => state.searchReducer?.savedArticles
  );
  const savedArticlesOfAuthorSearch = useAppSelector(
    (state) => state.authorReducer.savedArticles
  );
  const profileData = useAppSelector((state) => state.login);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );
  let location = useLocation();
  // const searchTerm = useAppSelector((state) => state.searchReducer.searchTerm);
  let loginId: any = useSelector((state: LoginCred) => state?.login?.user_id);
  let composeData = [
    {
      search_term: searchTerm,
      article_id: data?.article_id,
    },
  ];
  useEffect(() => {
    let userId = sessionStorage.getItem("isProfileUser");
    if (userId == "true") {
      setIsProfileUser(true);
    } else if (userId == "false") {
      setIsProfileUser(false);
    }
  }, []);

  const handleEmailModal = () => {
    if (allowProfile) {
      setEmailPopUp(!emailModal);
    } else {
      notify("error", "Login as profile user");
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCitation(event.target.value as string);
    let id;
    if (event.target.value === "APA - American Psychological Association") {
      id = 1;
    } else if (event.target.value === "CHICAGO") {
      id = 2;
    } else if (event.target.value === "HARVARD") {
      id = 3;
    } else if (event.target.value === "MLA- Modern Language Association") {
      id = 4;
    } else if (event.target.value === "VANCOUVER") {
      id = 5;
    }
    handleCiteApi(id);
  };

  const handleCite = async () => {
    setCite(!cite);
    setCitation("APA - American Psychological Association");
    handleCiteApi(1);

    // let response = await citeArticle();

    if (!cite) {
      let authorList = "";
      let publisher = "";
      const response = await getCiteArticleDetails();
      setciteDetailsDropDown(response?.data.data[0]);
      // response?.data.data[0][0][0]?.dat_cite_type_master_id
      handleCiteApi(response?.data.data[0][0][0]?.dat_cite_type_master_id);
    }
  };

  const handleCiteApi = async (citeMasterId: any) => {
    let res = await getCiteDescription(data?.article_id, citeMasterId);
    const citeResult = res?.data?.data[0]?.cite_result;
    setArticleApiDetails(citeResult);
  };

  function handleCheckBox(data: any, id: string) {
    getMarkedResult && getMarkedResult(data, id);
  }

  function closeAddToFolder() {
    setOpenFolder(!openFolder);
  }

  const handleOpenViewModal = () => {
    if (allowProfile) {
      setOpenViewModal(true);
    } else {
      notify("error", "Access Denied!");
    }
  };

  const handleCloseViewModal = () => setOpenViewModal(false);

  // const userid = sessionStorage.getItem("user_id");
  const userid = sessionStorage.getItem("user_id");

  const getUserData = async () => {
    const UserData: any = await profileDetailsAPI({ userid });

    setEmail(UserData.data?.data[0][0].emailid);
    setReciepientName(UserData.data?.data[0][0].firstname);
  };
  // const selectedFilters = useAppSelector((state) => state.filterReducer);

  // const contextId =
  //   selectedFilters.collectionValue === "J-GateCollection"
  //     ? 1
  //     : selectedFilters.collectionValue === "ConsortiaSubscriptions"
  //     ? 2
  //     : selectedFilters.collectionValue === "MyLibraryCollection"
  //     ? 3
  //     : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
  //     ? 4
  //     : selectedFilters.collectionValue === "ONOSCollections"
  //     ? 5
  //     : selectedFilters.collectionValue === "Library OPAC"
  //     ? 6
  //     : selectedFilters.collectionValue === "Open Access Collections"
  //     ? 7
  //     : null;

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state) => state.browseFilterSlice
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logPrintUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handlePrint = async (e: any) => {
    e.preventDefault();
    try {
      getUserData();

      const loggedin_customer_id = sessionStorage.getItem("user_id") || loginId;
      let emailData = [
        {
          search_term: searchTerm,
          article_id: data?.article_id,
        },
      ];

      let obj = JSON.stringify(emailData);

      const download_type = 3;
      const selected_type = 1;

      const win: Window | null = window.open(
        `${api.baseApi}/api/ArticleMetadataDownload/ArticleMetadataDownload?loggedin_customer_id=${loggedin_customer_id}&article_details=${obj}&download_type=${download_type}&selected_type=${selected_type}`
      );
      // const win: Window | null = window.open(printVal);
      if (win) {
        win.onload = () => {
          win.print();
        };
      }
      logPrintUsageData(99, data);
    } catch (error) {
      console.log("error", error);
    }
  };

  async function removeArticle(article_id: string, folderId: typeof folder_id) {
    try {
      if (!article_id) return;
      if (!folderId) return;

      dispatch(
        setModal({
          modalType: "DELETE_FOLDER_ARTICLE",
          modalProps: {
            show: true,
            article_id,
            folderId,
          },
        })
      );
    } catch (err) {
      notify("error", "Something went wrong!");
    }
  }

  // function updateFolderId() {
  //   try {
  //     if (folderId) return;
  //     setFolderID(undefined);
  //     if (!Array.isArray(savedArticles)) return;

  //     let SavedArticle = savedArticles.filter(
  //       (obj) => obj?.article_id == data?.article_id
  //     );

  //     if (SavedArticle.length === 1) {
  //       let id = SavedArticle[0].folder_id;
  //       if (id) {
  //         setFolderID(Number(id));
  //       }
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  function findFolderId(article_id: string | number): number | null {
    if (folderId) return folderId;
    if (!Array.isArray(savedArticles)) return null;

    let newArr = [...savedArticles];
    if (savedArticlesOfAuthorSearch?.length) {
      newArr = [...newArr, ...savedArticlesOfAuthorSearch];
    }

    let SavedArticle = newArr.filter((obj) => obj?.article_id == article_id);

    if (SavedArticle.length == 1) {
      let id = SavedArticle[0].folder_id;
      if (id) {
        return Number(id);
      }
    }
    return null;
  }

  function openDeleteArticleModal(
    article_id: string,
    folderId: typeof folder_id
  ) {
    dispatch(
      setModal({
        modalType: "REMOVE_ARTICLE",
        modalProps: {
          isOpen: true,
          article_id,
          folderId,
          setJournalResults,
        },
      })
    );
  }

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  return (
    <MuiCard
      sx={{
        marginTop: "1%",
        border: ".2px solid #D1D5DB",
        borderRadius: "12px",
        padding: "4px",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Checkbox
            onChange={() => handleCheckBox(data, data?.article_id)}
            checked={
              Array.isArray(markedArticlesid) &&
              markedArticlesid.includes(data?.article_id)
            }
          />
          {!data?.sub_data_type &&<Typography
            maxWidth={{ xs: 300, sm: 400, md: 600, lg: 800 }}
            fontFamily={"Inter"}
            fontWeight="500"
            fontSize={12}
            sx={{
              alignSelf: "center",
              textAlign: "center",
              borderRadius: 1,
              backgroundColor: Colors.purple100,
              margin: 1,
              padding: "2px  10px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              cursor: "default",
            }}
          >
            {[dataTypes.journals, dataTypes.journalArticle].includes(
              data?.data_type
            )
              ? dataTypes.journalArticle
              : data?.data_type}
          </Typography>}
          {data?.sub_data_type && <Typography
            maxWidth={{ xs: 300, sm: 400, md: 600, lg: 800 }}
            fontFamily={"Inter"}
            fontWeight="500"
            fontSize={12}
            sx={{
              alignSelf: "center",
              textAlign: "center",
              borderRadius: 1,
              backgroundColor: Colors.purple100,
              margin: 1,
              padding: "2px  10px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
              cursor: "default",
            }}
          >
           
              { data?.sub_data_type}
          </Typography>}
          {data?.fulltext ? (
            <Typography
              fontFamily={"Inter"}
              fontWeight="500"
              fontSize={12}
              sx={{
                alignSelf: "center",

                textAlign: "center",
                borderRadius: 1,
                backgroundColor: Colors.green100,
                margin: 1,
                padding: "2px  10px",
                cursor: "default",
              }}
            >
              Full Text
            </Typography>
          ) : null}

          {data?.peer_reviewed ? (
            <MuiTooltip title="Peer Reviewed">
              <PeopleAltOutlinedIcon
                sx={{
                  alignSelf: "center",
                  color: Colors.primary,
                }}
              ></PeopleAltOutlinedIcon>
            </MuiTooltip>
          ) : null}
        </Box>

        <Box>
          {allowProfile && (
            <>
              <Tooltip
                handleChange={handleEmailModal}
                icon={<EmailIcon />}
                title="Email"
              />
              {!isOpac && (
                <>
                  {folderId ? (
                    <Tooltip
                      title="Delete"
                      handleChange={() =>
                        removeArticle(data?.article_id, folder_id)
                      }
                      icon={<DeleteIcon />}
                    />
                  ) : findFolderId(data?.article_id) ? (
                    <Tooltip
                      title="Delete from Folder"
                      handleChange={() =>
                        openDeleteArticleModal(
                          data?.article_id,
                          findFolderId(data?.article_id)
                        )
                      }
                      icon={<DeleteIcon />}
                    />
                  ) : (
                    <Tooltip
                      icon={<FolderIcon />}
                      title="Add to Folder"
                      handleChange={() => {
                        if (isProfileUser) {
                          setOpenFolder(true);
                        } else {
                          showIsProfileToast(
                            "Please login as profile user to add the item to folder"
                          );
                        }
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}

          <Tooltip
            icon={<PrintIcon />}
            title="Print"
            // handleChange={printData}
            handleChange={handlePrint}
          />
          {!isOpac && (
            <Tooltip
              icon={<FormatQuoteIcon />}
              title="Cite"
              handleChange={() => {
                handleCite();
              }}
            />
          )}

          {!isOpac && (
            <Tooltip
              icon={<img src={BrokenLinkIcon} alt="BrokenLinkIcon" />}
              title="Report Link Issue"
              handleChange={handleOpenViewModal}
            />
          )}
          {/* <Tooltip
            icon={<FileDownloadIcon />}
            title="Download"
            handleChange={() => {}}
          />
          <Tooltip icon={<TextsmsIcon />} title="SMS" handleChange={() => {}} />
          <Tooltip
            icon={<BookmarkOutlinedIcon />}
            title="Bookmark"
            handleChange={() => {}}
          /> */}
        </Box>
      </Box>
      {children}
      <ComposeEmail
        handleEmailModal={handleEmailModal}
        state={emailModal}
        // data?.article_id
        id={composeData}
        articleData={data}
        resultFrom={resultFrom}
      />
      <CiteArticle
        setCitation={setCitation}
        citation={citation}
        articleApiDetails={articleApiDetails}
        citeDetailsDropDown={citeDetailsDropDown[0]}
        dialogueData={data}
        openViewModal={cite}
        handleCloseViewModal={() => handleCite()}
        handleChange={handleChange}
        data={data}
        composeData={composeData}
        resultFrom={resultFrom}
      />
      <ReportIssue
        openViewModal={openViewModal}
        handleCloseViewModal={handleCloseViewModal}
        id={data?.article_id}
        vol={data?.volumefrom}
        issue={data?.issuenumberfrom}
        articledoi={data?.articledoi}
        resourcemaster_id={data?.resourcemaster_id}
      />
      <AddToFolder
        isOpen={openFolder}
        handleClose={closeAddToFolder}
        articleId={data?.article_id}
        setJournalResults={setJournalResults}
        articleData={data}
        resultFrom={resultFrom}
      />
    </MuiCard>
  );
};

export default Card;
