const downloadFile = (content: string, fileName: string) => {
  const blob = new Blob([content], { type: "application/vnd.ms-excel" });
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", fileName + ".xls");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Clean up the DOM
};

export const downloadCitation = (array: any[], title: string) => {
  let rows = `
    <tr>
      <th>Index</th>
      <th>Authors</th>
      <th>Title</th>
      <th>URL</th>
      <th>Issue</th>
      <th>Volume</th>
    </tr>
  `;

  array.forEach((item, index) => {
    rows += `
      <tr>
        <td>${index + 1}</td>
        <td>${item?.authors || ''}</td>
        <td>${item?.article_title || ''}</td>
        <td>${item?.article_url || ''}</td>
        <td>${item?.issue || ''}</td>
        <td>${item?.volume || ''}</td>
      </tr>
    `;
  });

  const content = `
    <html xmlns:x="urn:schemas-microsoft-com:office:excel">
    <head>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <style>
        table, th, td {
          border: 1px solid black;
          border-collapse: collapse;
        }
        th, td {
          padding: 5px;
        }
      </style>
    </head>
    <body>
      <table>
        ${rows}
      </table>
    </body>
    </html>
  `;

  downloadFile(content, title);
};
