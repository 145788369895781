import {
  Box,
  IconButton,
  Typography,
  Checkbox,
  Card,
  CardContent,
  Divider,
  Button,
  Collapse,
  Alert,
  SelectChangeEvent,
} from "@mui/material";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

import Tooltip from "../../components/tootTips/hoverOverToolTips";
import IdIcon from "../../assets/img/id.png";

// Icons
import EmailIcon from "@mui/icons-material/Email";
import FolderIcon from "@mui/icons-material/Folder";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import ArrowDown from "@mui/icons-material/KeyboardArrowDownOutlined";
import ArrowUp from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Colors, dataTypes } from "../../utils/constants";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  isStringOfArray,
  showIsProfileToast,
  sortAuthor,
  sortAuthorDetail,
} from "../../utils/helper";
import AddToFolder from "../../components/resultsPopups/AddToFolder";
import NoRecordsFound from "../../components/NoRecordsFound";
import {
  updateMarkedArticleCount,
  updateMarkedArticleId,
  updateMarkedResult,
} from "../../store/slice/searchSlice";
import ComposeEmail from "../../components/basicSearchTopBar/composeEmail";

import { onSearch as search } from "../../screens/Search/onSearch";
import metaData from "../../utils/metadata";
import { profileDetailsAPI } from "../../api/userApi";
import { notify } from "../../utils/Notify";
import {
  getCiteArticleDetails,
  getCiteDescription,
} from "../../api/citeArticle";
import objectToQueryString from "../browseJournal/objToQuery";
import CiteArticle from "../../components/resultsPopups/CiteArticle";
import GotoTopButton from "../../components/gotoTop";
import api from "../../api/endpoints";
import { getAbstractStatus } from "../../api/Search/BasicSearch.api";
import { Analytics } from "@mui/icons-material";
import React, { useEffect } from "react";

interface IdNameValue {
  searchTerm: any;
  article_id: any;
}

const MarkedResult = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedIndex, setSelectedIndex] = useState("");
  const [articleIds, setArticleIds] = useState<string[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [emailModal, setEmailPopUp] = useState(false);
  const [composeData, setComposeData] = useState<any>([]);
  const [email, setEmail] = useState("");
  const [reciepientName, setReciepientName] = useState("");
  const [selectedArticle, setSelectedArticle] = useState<any>([]);
  const [cite, setCite] = useState(false);
  const [citeDetailsDropDown, setciteDetailsDropDown] = useState<any>([]);
  const [articleApiDetails, setArticleApiDetails] = useState<any>();
  const [citation, setCitation] = useState<string>(
    "APA - American Psychological Association"
  );
  const [idNameValues, setIdNameValues] = useState<IdNameValue[]>([]);
  const [showAbstract, setShowAbstract] = useState(false);
  const [emailData, setEmailData] = useState<any>([]);
  // Add to folder modal
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const markedResult = useAppSelector(
    (state) => state.searchReducer.markedResult
  );

  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );

  let customerId: any =
    useAppSelector((state) => state?.login?.informaticscustomer_id) ||
    sessionStorage.getItem("informaticscustomer_id");

  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const allowProfile = customerDetails?.allow_profile;

  const markedArticleId = useAppSelector(
    (state) => state.searchReducer.markedArticleId
  );

  function handleExpandClick(article_id: string, data: any) {
    if (selectedIndex === article_id) {
      setSelectedIndex("");
    } else {
      setSelectedIndex(article_id);
    }
    setShowAbstract(false);
    getAbstract(
      data?.article_id,
      data?.resourceissue_id,
      data?.resourcemaster_id,
      data?.resource_type
    );
  }

  function closeAddToFolder() {
    setIsOpen(false);
  }

  function handleCheckBox(id: string, data: any) {
    if (articleIds.includes(id)) {
      setArticleIds(() => articleIds.filter((obj) => obj !== id));
      setSelectedArticle((prev: any) =>
        prev.filter((obj: any) => obj?.article_id != id)
      );
      setEmailData(() =>
        emailData.filter((obj: any) => obj?.article_id !== id)
      );
    } else if (articleIds.length) {
      setArticleIds((prev) => [...prev, id]);
      if (data) setSelectedArticle((prev: any) => [...prev, data]);
      setEmailData((pre: any) => [
        ...pre,
        { search_term: data?.searchTerm, article_id: id },
      ]);
    } else {
      setArticleIds([id]);
      setSelectedArticle([data]);
      setEmailData([
        {
          search_term: data?.searchTerm,
          article_id: id,
        },
      ]);
    }
  }

  function handleAlert(status: boolean, message: string) {
    setShowAlert(status);
    setAlertMessage(message);

    setTimeout(() => {
      setShowAlert(false);
      setAlertMessage("");
    }, 2000);
  }

  function selectAll(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      setEmailData((prevEmailData: any) => [
        ...prevEmailData,
        // ...markedResult?.map((ele: any) => ({
        ...idNameValues?.map((ele: any) => ({
          search_term: ele?.searchTerm,
          article_id: ele?.article_id,
        })),
      ]);

      setArticleIds(markedArticleId);
      // setSelectedArticle(markedResult);
      setSelectedArticle(idNameValues);
    } else {
      setArticleIds([]);
      setSelectedArticle([]);
      setEmailData([
        {
          search_term: "",
          article_id: "",
        },
      ]);
    }
  }

  function removeArticles() {
    if (!selectedArticle?.length) {
      notify("info", "Please select one or more items to remove");
      return;
    }
    if (
      !Array.isArray(articleIds) &&
      Array.isArray(markedResult) &&
      Array.isArray(markedArticleId)
    ) {
      return;
    }
    let markedId: string[] = [];
    let markedResultData = [];

    for (let obj of markedArticleId) {
      if (!articleIds.includes(obj)) {
        markedId.push(obj);
      }
    }
    for (let obj of markedResult) {
      if (!articleIds.includes(obj?.article_id)) {
        markedResultData.push(obj);
      }
    }

    dispatch(updateMarkedArticleId(markedId));
    dispatch(updateMarkedResult(markedResultData));
    setArticleIds([]);
    setSelectedArticle([]);
    dispatch(updateMarkedArticleCount(markedId));
  }

  const handleEmailModal = () => {
    setEmailPopUp(!emailModal);
  };

  function getCorrespondenceEmail(arr: any): string | null {
    try {
      if (!Array.isArray(arr)) return arr;
      let data = arr?.map((item) => JSON.parse(item));
      if (!Array.isArray(data)) return null;

      // Filter the author_type_id = 4 data

      const filterdData = data.filter((obj) => obj?.authortype_id === 4);

      const email = filterdData?.map((x) => x?.author_email);
      if (email.length) return email.toString();
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  function getCorrespondenceAddress(arr: any): string | null {
    try {
      if (!Array.isArray(arr)) return arr;
      let data = arr?.map((item) => JSON.parse(item));
      if (!Array.isArray(data)) return null;

      // Filter the author_type_id = 4 data

      const filterdData = data.filter((obj) => obj?.authortype_id === 4);

      const address = filterdData?.map((x) => x?.author_affiliation);
      if (address.length) return address.toString();
      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  const getUserData = async () => {
    const userid = sessionStorage.getItem("user_id");
    const UserData: any = await profileDetailsAPI({ userid });

    setEmail(UserData.data?.data[0][0].emailid);
    setReciepientName(UserData.data?.data[0][0].firstname);
  };

  const handlePrint = async () => {
    try {
      getUserData();
      if (!Array.isArray(selectedArticle)) {
        notify("info", " Please select one or more items to print!");
        return;
      }
      if (selectedArticle.length === 0) {
        notify("info", " Please select one or more items to print!");
        return;
      }

      const article_id = selectedArticle?.map((obj: any) => obj?.article_id);
      const searchTerm = selectedArticle?.map((obj: any) => obj?.searchTerm);
      // const loggedin_customer_id = sessionStorage.getItem(
      //   "informaticscustomer_id"
      // );
      const loggedin_customer_id = sessionStorage.getItem("user_id");
      const article_ids = article_id.toString();
      const receipient_mail = email;
      const download_type = 3;
      const receipient_name = reciepientName;
      const search_keyword = searchTerm;
      const selected_type = 1;
      const remarks = "testing";
      //emailData
      let obj = JSON.stringify(emailData);

      const win: Window | null = window.open(
        `${api.baseApi}/api/ArticleMetadataDownload/ArticleMetadataDownload?loggedin_customer_id=${loggedin_customer_id}&article_details=${obj}&download_type=${download_type}&selected_type=${selected_type}`
      );

      if (win) {
        win.onload = () => {
          win.print();
        };
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   setEmailData((prevEmailData: any) => [
  //     ...prevEmailData,
  //     ...selectedArticle?.map((ele: any) => ({
  //       search_term: ele?.searchTerm,
  //       article_id: ele?.article_id,
  //     })),
  //   ]);
  // }, [selectedArticle]);

  function openEmailPopup() {
    if (!Array.isArray(selectedArticle)) {
      notify("info", " Please select one or more items to Mail!");
      return;
    }
    if (selectedArticle.length === 0) {
      notify("info", " Please select one or more items to Mail!");
      return;
    }

    if (allowProfile) {
      setEmailPopUp(true);
    } else {
      notify("error", "Access Denied!");
    }
  }

  function openAddFolderPopup() {
    // if not a profile user
    const isProfileUser = sessionStorage.getItem("isProfileUser");
    if (isProfileUser !== "true") {
      showIsProfileToast(
        "Please login as profile user to add the item to folder"
      );
      return;
    }

    if (!Array.isArray(selectedArticle)) {
      notify("info", "Please select one or more items to add into folder!");
      return;
    }
    if (selectedArticle.length === 0) {
      notify("info", "Please select one or more items to add into folder!");
      return;
    }
    setIsOpen(!isOpen);
  }

  const handleCite = async () => {
    selectedArticle.map((item: any) => {
      setComposeData((prevComposeData: any) => [
        ...prevComposeData,
        {
          search_term: item.searchTerm || "",
          article_id: item?.article_id || null,
        },
      ]);
    });
    if (!selectedArticle?.length) {
      notify("info", "Please select one or more items to cite");
      return;
    }
    if (cite === false) {
      setCite(true);
    } else if (cite === true) {
      setCite(false);
      return;
    }

    setCitation("APA - American Psychological Association");
    handleCiteApi(1);
    // let response = await citeArticle();

    if (!cite) {
      let authorList = "";
      let publisher = "";
      const response = await getCiteArticleDetails();
      setciteDetailsDropDown(response?.data.data[0]);
      // response?.data.data[0][0][0]?.dat_cite_type_master_id
      handleCiteApi(response?.data.data[0][0][0]?.dat_cite_type_master_id);
    }
  };

  const handleCiteApi = async (citeMasterId: any) => {
    let data = selectedArticle[0];
    let authorList = "";
    let publisher = "";
    data?.authors?.map((element: any) => {
      authorList += `${element} , `;
    });

    authorList = `${authorList.slice(0, -3)}`;

    data?.publisher_name?.map((element: any) => {
      publisher += `${element} OR `;
    });

    const articleIds = Array.isArray(selectedArticle)
      ? selectedArticle.map((obj) => obj?.article_id)
      : [];

    let res = await getCiteDescription(articleIds.toString(), citeMasterId);
    const apiData = res?.data?.data;

    setArticleApiDetails(apiData);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setCitation(event.target.value as string);
    let id;
    if (event.target.value === "APA - American Psychological Association") {
      id = 1;
    } else if (event.target.value === "CHICAGO") {
      id = 2;
    } else if (event.target.value === "HARVARD") {
      id = 3;
    } else if (event.target.value === "MLA- Modern Language Association") {
      id = 4;
    } else if (event.target.value === "VANCOUVER") {
      id = 5;
    }
    handleCiteApi(id);
  };

  const getAbstract = async (
    articleId: string | number,
    resourceissueId: number | null,
    resourcemasterId: number | null,
    resourceType: number | null
  ) => {
    try {
      const query = `?customer_id=${customerId}&article_id=${articleId}&issue_id=${resourceissueId}&resource_id=${resourcemasterId}&resource_type_id=${resourceType}`;
      const abstract: any = await getAbstractStatus(query);
      // setAbstractUrl(abstract[0][0].abstract_url);
      setShowAbstract(abstract[0][0].display_abstract);
    } catch (error: any) {
      setShowAbstract(false);
    }
  };

  const groupedItems = markedResult.reduce((acc: any, currentItem: any) => {
    const key = currentItem.searchTerm; // Replace 'yourKey' with the actual key you want to group by
    if (!acc[key]) {
      acc[key] = []; // Create a new array if the key doesn't exist in the accumulator
    }
    acc[key].push(currentItem);
    return acc;
  }, {});

  function extractIdNameValues(obj: any): IdNameValue[] {
    const idNameValues: IdNameValue[] = [];
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        for (const item of obj[key]) {
          const { searchTerm, article_id } = item;
          idNameValues.push({ searchTerm, article_id });
        }
      }
    }
    return idNameValues;
  }

  useEffect(() => {
    const extractedValues = extractIdNameValues(groupedItems);
    setIdNameValues(extractedValues);
  }, []);

  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        //  sx={{ marginInline: "50px", marginY: 2 }}
        sx={{ marginInline: "50px", marginTop: 2 }}
        justifyContent={"space-between"}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardArrowLeftOutlinedIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <Typography fontFamily={"Lora"} fontWeight={"600"} variant="h6">
            {customerDetails?.displayname}
          </Typography>
        </Box>

        {/* Icons */}
        <Box>
         {allowProfile && <> <Tooltip
            title="Email"
            handleChange={openEmailPopup}
            icon={<EmailIcon />}
          />
          <Tooltip
            title="Add to Folder"
            handleChange={openAddFolderPopup}
            icon={<FolderIcon />}
          /></>}
          <Tooltip
            title="Print"
            icon={<LocalPrintshopIcon />}
            handleChange={handlePrint}
          />
          <Tooltip
            title="Citation"
            handleChange={handleCite}
            icon={<FormatQuoteIcon />}
          />
          <Tooltip
            title="Delete"
            handleChange={removeArticles}
            icon={<DeleteIcon />}
          />
        </Box>
      </Stack>
      {/* Add to folder modal */}
      <AddToFolder
        isOpen={isOpen}
        handleClose={closeAddToFolder}
        articleId={articleIds}
        handleAlert={handleAlert}
        from="markedResult"
      />

      <ComposeEmail
        handleEmailModal={handleEmailModal}
        state={emailModal}
        id={emailData}
      />

      <CiteArticle
        citation={citation}
        articleApiDetails={articleApiDetails}
        citeDetailsDropDown={citeDetailsDropDown[0]}
        openViewModal={cite}
        handleCloseViewModal={() => handleCite()}
        handleChange={handleChange}
        data={selectedArticle[0]}
        composeData={composeData}
      />

      <Box className="mr-container">
        {showAlert && <Alert severity="success">{alertMessage}</Alert>}
        {markedArticleId.length > 0 && (
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              onChange={selectAll}
              checked={articleIds.length === markedArticleId.length}
            />

            <Typography color={"primary"}>Mark results</Typography>
          </Stack>
        )}

        {/* CARD ========================================================================================> */}
        {/* {markedResult?.length ? (
          markedResult?.map((data: any, i: number) => ( */}
        {markedResult?.length ? (
          Object.entries(groupedItems).map(([key, group]) => (
            <Card sx={{ my: 1 }}>
              <CardContent sx={{ padding: 0 }}>
                {/* SEARCH TERM ===========================================> */}
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  gap={2}
                  padding={"16px"}
                  justifyContent={"space-between"}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography fontSize={" 14px"} sx={{ color: "#6B7280" }}>
                      Search Term
                    </Typography>
                    <Typography
                      fontSize={" 14px"}
                      sx={{
                        border: "1px solid #E5E7EB",
                        paddingInline: "6px",
                        borderRadius: "6px",
                      }}
                    >
                      {key}
                    </Typography>
                  </Box>
                </Stack>

                <Divider />
                {/* Arrow ===========================================> */}
                <Stack>
                  {(group as any[]).map((data, i) => (
                    <>
                      <Stack
                        direction={"row"}
                        justifyContent={"flex-end"}
                        paddingX={1}
                      >
                        <IconButton
                          onClick={() =>
                            handleExpandClick(data?.article_id, data)
                          }
                        >
                          {data?.article_id === selectedIndex ? (
                            <ArrowUp />
                          ) : (
                            <ArrowDown />
                          )}
                        </IconButton>
                      </Stack>
                      <Box padding={"0px 16px"}>
                        {/***********************************************************************************************************************************************
                    Title Section
                  ***********************************************************************************************************************************************/}
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={2}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={-1}
                          >
                            <Checkbox
                              checked={articleIds.includes(data?.article_id)}
                              onChange={() =>
                                handleCheckBox(data?.article_id, data)
                              }
                            />
                            <Typography
                              sx={{
                                fontFamily: "Lora",
                                fontWeight: "600",
                                fontSize: "20px",
                              }}
                            >
                              {`${i + 1}.`}
                            </Typography>
                          </Stack>

                          <Typography
                            sx={{
                              fontFamily: "Lora",
                              fontWeight: "600",
                              fontSize: "20px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: ` ${data?.title}`,
                            }}
                          />
                        </Stack>

                        {/***********************************************************************************************************************************************
                     Author Details Section
                  ***********************************************************************************************************************************************/}
                        {[
                          dataTypes.journalArticle,
                          dataTypes.journals,
                          dataTypes.bookSeries,
                          dataTypes.dissertationThesis,
                          dataTypes.preprint,
                          dataTypes.conferenceProceedings,
                          dataTypes.magazine,
                          dataTypes.audioVideo,
                        ].includes(data?.data_type) ? (
                          <Stack
                            direction={"row"}
                            flexWrap={"wrap"}
                            textAlign={"justify"}
                          >
                            {Array.isArray(data?.author_details) &&
                              isStringOfArray(data?.author_details) &&
                              sortAuthor(data?.author_details)?.map(
                                (name: string, i: number) => {
                                  return (
                                    <Box
                                      display={"flex"}
                                      flexDirection={"row"}
                                      justifyContent={"space-between"}
                                      flexShrink={0}
                                    >
                                      <Button
                                        sx={{
                                          padding: 0,
                                          textDecoration: "underline",
                                          minWidth: 0,
                                          paddingLeft: i === 0 ? 0 : 1,
                                          paddingRight: 1,
                                          color: Colors.black,
                                        }}
                                      >
                                        {name.length ? name : ""}{" "}
                                      </Button>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          height: "60%",
                                          alignSelf: "center",
                                        }}
                                      />
                                    </Box>
                                  );
                                }
                              )}
                            {data?.author_details?.length &&
                            !isStringOfArray(data?.author_details) ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  marginTop: "5px",
                                  marginLeft: "70px",
                                }}
                                flexWrap="wrap"
                              >
                                {sortAuthorDetail(data?.author_details)?.map(
                                  (obj: any, i) => {
                                    const firstName = obj?.author_fname
                                      ? obj?.author_fname
                                      : "";
                                    const lastName = obj?.author_lname
                                      ? obj?.author_lname
                                      : "";

                                    const displayName = lastName
                                      ? lastName.replace(/,/g, " ") +
                                        ", " +
                                        firstName.replace(/,/g, " ")
                                      : firstName.replace(/,/g, " ");
                                    return (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          sx={{
                                            padding: 0,
                                            textDecoration: "underline",
                                            minWidth: 0,
                                            paddingLeft: i === 0 ? 0 : 1,
                                            paddingRight: 1,
                                            color: Colors.black,
                                          }}
                                        >
                                          {displayName.length
                                            ? displayName
                                            : ""}{" "}
                                        </Button>
                                        {obj?.orc_id ? (
                                          <div>
                                            <img
                                              src={IdIcon}
                                              style={{
                                                paddingLeft: "5px",
                                                marginTop: "8px",
                                                paddingRight: "6px",
                                              }}
                                              alt="IdIcon"
                                            />
                                          </div>
                                        ) : null}
                                        <Divider
                                          orientation="vertical"
                                          style={{
                                            height: "60%",
                                            alignSelf: "center",
                                          }}
                                        />
                                      </Box>
                                    );
                                  }
                                )}
                              </Box>
                            ) : null}
                          </Stack>
                        ) : null}
                        {/***********************************************************************************************************************************************
                    Journal name, Data, Year  Section
                  ***********************************************************************************************************************************************/}
                        {[
                          dataTypes.journalArticle,
                          dataTypes.journals,
                          dataTypes.bookSeries,
                          dataTypes.dissertationThesis,
                          dataTypes.preprint,
                          dataTypes.conferenceProceedings,
                          dataTypes.audioVideo,
                        ].includes(data?.data_type) ? (
                          <Typography
                            sx={{
                              textAlign: "left",
                              fontSize: "14px",
                              color: Colors.black,
                              fontStyle: "italic",
                              // paddingLeft: "0.85rem",
                              cursor: "pointer",
                              marginLeft: "70px",
                            }}
                          >
                            {metaData(data)}
                          </Typography>
                        ) : null}
                        <Collapse
                          in={data?.article_id === selectedIndex}
                          unmountOnExit
                        >
                          {/* <Box my={2}>
                      <Typography sx={styles.typographyTitle}>
                        Source
                      </Typography>
                      <Typography sx={styles.typography}>Journal</Typography>
                    </Box> */}

                          <Box my={2}>
                            <Typography sx={styles.typographyTitle}>
                              Type
                            </Typography>
                            <Typography sx={styles.typography}>
                              {data?.data_type ? data.data_type : "-"}
                            </Typography>
                          </Box>

                          <Box my={2}>
                            <Typography sx={styles.typographyTitle}>
                              Database
                            </Typography>
                            <Typography sx={styles.typography}>
                              J-Gate
                            </Typography>
                          </Box>

                          {data?.articledoi ? (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                DOI:
                              </Typography>
                              <Typography
                                sx={[
                                  styles.typography,
                                  { color: Colors.primary },
                                ]}
                              >
                                <p style={{ color: Colors.black }}>
                                  {" "}
                                  {data?.articledoi ? data?.articledoi : "-"}
                                </p>
                                {/* {data?.articledoi ? data?.articledoi : "-"} */}
                              </Typography>
                            </Box>
                          ) : null}

                          {data?.sjr_score ? (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                Source Ranking:
                              </Typography>
                              <Stack
                                direction={"row"}
                                gap={1}
                                alignItems={"center"}
                              >
                                {data?.sjr_score && (
                                  <Typography sx={styles.typography}>
                                    SJR: {data?.sjr_score}
                                  </Typography>
                                )}

                                {data?.sjr_hindex && (
                                  <Typography sx={styles.typography}>
                                    H-Index:{" "}
                                    <span style={{ color: Colors.gray_700 }}>
                                      {data?.sjr_hindex}
                                    </span>
                                  </Typography>
                                )}

                                {consortiamaster_id && data?.naas_value && (
                                  <Typography sx={styles.typography}>
                                    NAAS:{" "}
                                    <span style={{ color: Colors.gray_700 }}>
                                      {data?.naas_value}
                                    </span>
                                  </Typography>
                                )}
                              </Stack>
                            </Box>
                          ) : null}

                          {Array.isArray(data?.keywords) &&
                          data?.keywords.filter((item: any) => item !== "")
                            .length ? (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                Keywords:
                              </Typography>
                              <Stack
                                direction={"row"}
                                gap={1}
                                flexWrap={"wrap"}
                              >
                                {Array.isArray(data?.keywords) &&
                                  data?.keywords?.map(
                                    (name: string, i: number) => (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          sx={{
                                            fontFamily: "Helvetica Neue",
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            display: "flex",
                                            flexDirection: "row",
                                            flexShrink: 0,
                                            textTransform: "capitalize",
                                            padding:
                                              i === 0
                                                ? "0px 8px 0px 0px"
                                                : "0px 8px",
                                            color: Colors.black,
                                          }}
                                        >
                                          {name}
                                        </Button>
                                        <Divider
                                          orientation="vertical"
                                          style={{
                                            height: "50%",
                                            alignSelf: "center",
                                          }}
                                        />
                                      </Box>
                                    )
                                  )}
                              </Stack>
                            </Box>
                          ) : null}

                          {showAbstract && data?.abstract ? (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                Abstract:
                              </Typography>
                              <Typography sx={styles.typography}>
                                {/* {data?.abstract ? data.abstract : "-"} */}
                                <div
                                  style={{ fontSize: "14px" }}
                                  dangerouslySetInnerHTML={{
                                    __html: data?.abstract,
                                  }}
                                />
                              </Typography>
                            </Box>
                          ) : null}

                          {Array.isArray(data?.author_affiliation) && (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                Affiliation:
                              </Typography>
                              <Stack gap={0.5}>
                                {Array.isArray(data?.author_affiliation) &&
                                  data?.author_affiliation?.map(
                                    (name: any, i: number) => (
                                      <Typography sx={styles.typography}>
                                        {i + 1}. {name}
                                      </Typography>
                                    )
                                  )}
                              </Stack>
                            </Box>
                          )}

                          {/* <Box my={2}>
                      <Typography sx={styles.typographyTitle}>
                        Correspondence Address
                      </Typography>
                      <Typography sx={styles.typography}>
                        Lendzion R.J.; Department of Colorectal Surgery,
                        Australia;
                      </Typography>
                    </Box>

                    <Box my={2}>
                      <Typography sx={styles.typographyTitle}>Email</Typography>
                      <Typography
                        sx={[styles.typography, { color: Colors.primary }]}
                      >
                        {"test@gmail.com"}
                      </Typography>
                    </Box> */}

                          {Array.isArray(data?.publisher_name) &&
                          data?.publisher_name.length ? (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                Publisher
                              </Typography>
                              {Array.isArray(data?.publisher_name) &&
                                data.publisher_name?.map((name: string) => (
                                  <Typography sx={styles.typography}>
                                    {name}
                                  </Typography>
                                ))}
                            </Box>
                          ) : null}

                          {Array.isArray(data?.author_details) &&
                          !isStringOfArray(data?.author_details) &&
                          getCorrespondenceAddress(data?.author_details) ? (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                Correspondence Address
                              </Typography>
                              <Typography sx={styles.typography}>
                                {getCorrespondenceAddress(data?.author_details)}
                              </Typography>
                            </Box>
                          ) : null}

                          {Array.isArray(data?.author_details) &&
                          !isStringOfArray(data?.author_details) &&
                          getCorrespondenceEmail(data?.author_details) ? (
                            <Box my={2}>
                              <Typography sx={styles.typographyTitle}>
                                Email
                              </Typography>
                              <Typography sx={styles.typography}>
                                {getCorrespondenceEmail(data?.author_details)}
                              </Typography>
                            </Box>
                          ) : null}
                        </Collapse>
                      </Box>
                    </>
                  ))}
                </Stack>
              </CardContent>
            </Card>
          ))
        ) : (
          <NoRecordsFound />
        )}
        <GotoTopButton />
      </Box>
    </>
  );
};

export default MarkedResult;

const styles = {
  typography: {
    fontSize: "14px",
  },
  typographyTitle: {
    fontSize: "14px",
    fontWeight: "700",
  },
};
