import { createBrowserRouter } from "react-router-dom";

// Important Components
import AuthLayout from "../layouts/AuthLayout";
import CheckEmail from "../components/CheckEmail";
import ForgotPassword from "../screens/ForgotPassword";
import Login from "../screens/Login";
import Search from "../screens/Search/index";
import SignUp from "../screens/SignUp";

//Lazy Components
// import {
//   BasicSearchScreen,
//   AdvancedSearch,
//   AuthorList,
//   AuthorFinderSearch,
//   Preferences,
//   BrowseJournalSearch,
//   BrowseJournalTableSearch,
//   PackageArticle,
//   AboutUs,
//   MyFolder,
//   Alerts,
//   ExternalFolders,
//   ExternalLinksDetails,
//   SearchHistory,
//   AbstractFullScreen,
//   DdrScreen,
//   FindLibrary,
//   MarkedResult,
//   PersonalLibrary,
//   AuthorFinder,
//   MyFolderDetail,
//   Profile,
//   SavedSearch,
// } from "./LazyRoutes";

import BasicSearchScreen from "../screens/basicSearchScreen/basicSearchScreen";
import AuthorFinder from "../screens/AuthorFinder/AuthorFinder";
import AdvancedSearch from "../screens/AdvancedSearch";
import AuthorList from "../screens/AuthorFinder/AuthorList";
import AuthorFinderSearch from "../screens/AuthorFinder/AuthorFinderSearch";
import Preferences from "../screens/Preferences/Preferences";
import BrowseJournalTableSearch from "../screens/browseJournal/browserJournalTableScreen";
import BrowseJournalSearch from "../screens/browseJournal/browserJournalSearch";
import AbstractFullScreen from "../screens/basicSearchScreen/abstractFullScreen";
import DdrScreen from "../components/ddrScreen/ddrScreen";
import FindLibrary from "../screens/FindLibrary/findLibrary";
import MarkedResult from "../screens/Search/MarkedResult";
import MyFolder from "../screens/MyLibrary/Folders/MyFolder";
import Alerts from "../screens/MyLibrary/MyAlerts/Alerts";
import SavedSearch from "../screens/MyLibrary/SavedSearch/SavedSearch";
import ExternalLinksDetails from "../screens/MyLibrary/Links/ExternalLinksDetails";
// import SearchHistoryLegacy from "../screens/SearchHistory/Search_history(legacy)";
import SearchHistory from "../screens/SearchHistory/SearchHistory";
import ExternalFolders from "../screens/MyLibrary/Links/ExternalFolders";
import PackageArticle from "../components/browserJournalblocks/packageArticle";
import AboutUs from "../screens/AboutUs/AboutUs";
import MyFolderDetail from "../screens/MyLibrary/Folders/MyFolderDetail";
import PersonalLibrary from "../screens/MyLibrary/index";
import Profile from "../screens/Profile/index";
import ChangePassword from "../screens/ChangePassword";
import ThankYouScreen from "../screens/ThankYou.screen";
import SSO from "../screens/SSO";
import { isMobile } from "react-device-detect";
import CheckMobOrWeb from "../screens/CheckMobOrWeb";
import ProfileChangePassword from "../screens/ProfileChangePassword";
import { BookSearch } from "../Book/bookSearch";

let router: any;
{
  isMobile
    ? (router = createBrowserRouter([
        {
          path: "/",
          element: <CheckMobOrWeb />,
        },
      ]))
    : (router = createBrowserRouter([
        {
          path: "/",
          element: (
            <AuthLayout>
              <Search />
            </AuthLayout>
          ),
        },
        {
          path: "/login",
          element: <Login />,
        },
        {
          path: "/signup",
          element: <SignUp />,
        },
        {
          path: "/resetpassword",
          element: <ForgotPassword />,
        },
        {
          path: "/checkemail",
          element: <CheckEmail />,
        },
        {
          path: "/search",
          element: (
            <AuthLayout>
              <Search />
            </AuthLayout>
          ),
        },
        {
          path: "/basicSearchScreen",
          element: (
            <AuthLayout>
              <BasicSearchScreen />
            </AuthLayout>
          ),
        },
        {
          path: "/authorfinder",
          element: (
            <AuthLayout>
              <AuthorFinder />
            </AuthLayout>
          ),
        },
        {
          path: "/search-builder",
          element: (
            <AuthLayout>
              <AdvancedSearch />
            </AuthLayout>
          ),
        },
        {
          path: "/authorlist",
          element: (
            <AuthLayout>
              <AuthorList />
            </AuthLayout>
          ),
        },
        {
          path: "/authorfindersearch",
          element: (
            <AuthLayout>
              <AuthorFinderSearch />
            </AuthLayout>
          ),
        },
        {
          path: "/preferences",
          element: (
            <AuthLayout>
              <Preferences />
            </AuthLayout>
          ),
        },
        {
          path: "BrowseJournalTableSearch/BrowseJournalSearch",
          element: (
            <AuthLayout>
              <BrowseJournalSearch />,
            </AuthLayout>
          ),
        },
        {
          path: "/BrowseJournalTableSearch",
          element: (
            <AuthLayout>
              <BrowseJournalTableSearch />
            </AuthLayout>
          ),
        },

        {
          path: "BrowseJournalTableSearch/PackageArticle",
          element: (
            <AuthLayout>
              <PackageArticle />,
            </AuthLayout>
          ),
        },
        {
          path: "/aboutus/:id",
          element: (
            <AuthLayout>
              <AboutUs />
            </AuthLayout>
          ),
        },
        {
          path: "/my-library",
          element: (
            <AuthLayout>
              <PersonalLibrary />
            </AuthLayout>
          ),
          children: [
            {
              path: "folders",
              element: <MyFolder />,
            },
            {
              path: "folders/:id",
              element: <MyFolderDetail />,
            },
            {
              path: "alerts",
              element: <Alerts />,
            },
            {
              path: "searches",
              element: <SavedSearch />,
            },
            {
              path: "links",
              element: <ExternalFolders />,
            },
            {
              path: "links/:id",
              element: <ExternalLinksDetails />,
            },
          ],
        },
        {
          path: "/profile",
          element: (
            <AuthLayout>
              <Profile />
            </AuthLayout>
          ),
        },
        // {
        //   path: "/searchhistory",
        //   element: (
        //     <AuthLayout>
        //       <SearchHistoryLegacy />,
        //     </AuthLayout>
        //   ),
        // },
        {
          path: "/searchhistory",
          element: (
            <AuthLayout>
              <SearchHistory />,
            </AuthLayout>
          ),
        },

        {
          path: "abstractFullScreen",
          element: (
            <AuthLayout hideFooter>
              <AbstractFullScreen />
            </AuthLayout>
          ),
        },
        {
          path: "basicSearchScreen/DdrScreen",
          element: <DdrScreen />,
        },
        {
          path: "basicSearchScreen/FindLibrary",
          element: <FindLibrary />,
        },
        {
          path: "/my-library/folders/:id/FindLibrary",
          element: <FindLibrary />,
        },
        {
          path: "authorfindersearch/DdrScreen",
          element: <DdrScreen />,
        },
        {
          path: "/my-library/folders/:id/DdrScreen",
          element: <DdrScreen />,
        },
        {
          path: "BrowseJournalTableSearch/BrowseJournalSearch/DdrScreen",
          element: <DdrScreen />,
        },
        {
          path: "authorfindersearch/FindLibrary",
          element: <FindLibrary />,
        },
        {
          path: "BrowseJournalTableSearch/BrowseJournalSearch/findlibrary",
          element: <FindLibrary />,
        },

        {
          path: "/marked-result",
          element: (
            <AuthLayout>
              <MarkedResult />
            </AuthLayout>
          ),
        },
        {
          path: "/changepassword",
          element: <ChangePassword />,
        },
        {
          path: "/changepassword/:user_id/:password_token",
          element: <ChangePassword />,
        },
        {
          path: "/profilechangepassword/:user_id",
          element: <ProfileChangePassword />,
        },
        {
          path: "/thankYou",
          element: <ThankYouScreen />,
        },
        {
          path: "/transfertodiskover",
          element: <SSO />,
        },
        {
          path: "/BookSearch",
          element: (
            <AuthLayout>
              <BookSearch />
            </AuthLayout>
          ),
        },
        {
          path: "/basicSearchScreen/",
          element: (
            <AuthLayout>
              {/* <Search /> */}
              <BasicSearchScreen />
            </AuthLayout>
          ),
        },
      ]));
}

export default router;
