import http from "../httpService";
import {
  GetAutoCompleteRoot,
  GetAutoCompleteKeyword,
} from "../../types/GetAutocomplete.types";
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export const useGetAutoComplete = (query: string) => {
  return useQuery(
    ["GetAutoComplete", query],
    () =>
      http.get(
        "/api/indexingGen/solrGeneral/autofillcore?page=1&rows=1&facet_limit=25&facet_fields=keyword&datsubjectmasterlevel1_ids=(5)",
        {
          params: {
            facet_prefix: query,
          },
        }
      ),
    {
      select: (
        res: AxiosResponse<GetAutoCompleteRoot>
      ): GetAutoCompleteKeyword[] => {
        return res.data.data.facets.facet_fields.keyword;
      },
      enabled: query.length >= 3, //Wont work for <2 chars
    }
  );
};
