import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// Icons
// Assets
import "./style.css";
import useOnClickOutside from "../../hooks/useOutSideClick";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useGetUserProfile } from "../../api/Profile.api";
import { updatePersonalDetails } from "../../store/slice/customerDetails";

export const useMainNav = () => {
  const navigate = useNavigate();
  const drawerRef = useRef(null);
  const appBarRef = useRef(null);
  const location = useLocation();
  const dispatch = useAppDispatch()

  const userId = useAppSelector((state) => state.login.user_id);
  const customerDetails = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const markedResult = useAppSelector(
    (state) => state.searchReducer.markedResult
  );

  const [toggleNav, setToggleNav] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  //To Display Profile Image
  const { data: me, refetch } = useGetUserProfile(userId, false);

  function fetchDetails() {
    if (!me) return;
    if (me?.user_type === 2) {
      sessionStorage.setItem("isProfileUser", "true");
    } else {
      sessionStorage.setItem("isProfileUser", "false");
    }
    dispatch(updatePersonalDetails(me))
  }
  useEffect(() => {
    fetchDetails();
  }, [me]);

  useEffect(() => {
    refetch();
    const unloadCallback = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const message =
        "Are you sure you want to leave? All provided data will be lost.";
      event.returnValue = message;
      return message;
    };
    if (location.pathname === "/" || location.pathname === "/search" || location.pathname === "/basicSearchScreen/") return;

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [refetch]);

  const closeMenu = () => setAnchorEl(null);

  const closeNav = () => setToggleNav(false);

  useOnClickOutside(drawerRef, closeNav, appBarRef);

  const handleDrawerToggle = () => setToggleNav(!toggleNav);

  const handleClick = (e: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget);

  return {
    navigate,
    drawerRef,
    appBarRef,
    toggleNav,
    anchorEl,
    closeNav,
    customerDetails,
    markedResult,
    open,
    me,
    closeMenu,
    handleDrawerToggle,
    handleClick,
  };
};
