import React from "react";

function CheckMobOrWeb() {
  return (
    <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center" ,height:"100vh"}}>
      <>
        <p>Please download the mobile app</p>
        <p>
          Download the app:
        </p>
        <div style={{display:"flex"}}>

          <a href="https://play.google.com/store/apps/details?id=com.informatics.jgate">Android App</a> 
          <p> &nbsp; | &nbsp; </p>
          <a href="https://apps.apple.com/us/app/j-gate/id1670549103"> iOS App</a>
        </div>
      </>
    </div>
  );
}

export default CheckMobOrWeb;
