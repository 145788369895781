import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
// Icons
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
// Assets
import logo from "../../assets/img/logo.png";
import "./style.css";
import { Stack } from "@mui/system";
import ProfileMenu from "./ProfileMenu";
import { navStyles as sx } from "./Nav.style";
import SideDrawer from "./SideDrawer";
import { Tooltip } from "@mui/material";
import { useMainNav } from "./MainNav.hook";
import { usagelogApi, usageReport } from "../../api/usageReportApi";
import { useSelector } from "react-redux";

const MainNav = () => {
  const {
    navigate,
    drawerRef,
    appBarRef,
    toggleNav,
    anchorEl,
    closeNav,
    customerDetails,
    markedResult,
    open,
    me,
    closeMenu,
    handleDrawerToggle,
    handleClick,
  } = useMainNav();
  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const logBasicSearchUsageData = (usageActionID: any) => {
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  return (
    <>
      <AppBar color="inherit" position="sticky" className="nav" ref={appBarRef}>
        <Toolbar sx={sx.toolbar}>
          <Stack direction={"row"} alignItems={"center"}>
            {/* Hamburger Menu */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              {toggleNav ? <CloseIcon /> : <MenuIcon />}
            </IconButton>

            {/* Logo */}
            <img
              src={logo}
              alt="logo"
              className="nav-logo"
              onClick={() => {
                logBasicSearchUsageData(8);
                navigate("/search");
              }}
            />
            {/* {(profileData1?.banner != "\\x" &&
              profileData1?.banner != null &&
              profileData1?.banner) ||
            (profileData1?.logo !== null &&
              profileData1?.logo !== "\\x" &&
              profileData1?.logo) ? (
              profileData1?.banner &&
              profileData1?.banner != "\\x" &&
              profileData1?.banner != null ? (
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
                  <img src={profileData1?.banner} width={70}></img> 
                  <img
                    src={profileData1?.banner}
                    style={{
                      width: "100px",
                      height: "70px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : (profileData1?.banner == "\\x" ||
                  profileData1?.banner == null ||
                  !profileData1?.banner) &&
                profileData1?.logo ? (
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
               <img src={profileData1?.logo} width={70}></img> 
                  <img
                    src={profileData1?.logo}
                    style={{
                      width: "100px",
                      height: "70px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : null
            ) : null} */}

            <Box sx={sx.brandWrapper}>
              <Text sx={sx.consortia}>
                {customerDetails?.consortianame
                  ? customerDetails?.consortianame
                  : ""}
              </Text>
              <Text
                sx={{
                  ...sx.name,
                  // fontSize: customerDetails?.consortianame ? "0.75rem" : "1rem",
                  fontSize: customerDetails?.consortianame
                    ? // ? "1.0625rem"
                      "22px"
                    : sx.diplyaName,
                }}
              >
                {customerDetails?.domainname
                  ? customerDetails?.domainname
                  : customerDetails?.displayname}
              </Text>
            </Box>
            {(profileData1?.banner != "\\x" &&
              profileData1?.banner != null &&
              profileData1?.banner) ||
            (profileData1?.logo !== null &&
              profileData1?.logo !== "\\x" &&
              profileData1?.logo) ? (
              profileData1?.banner &&
              profileData1?.banner != "\\x" &&
              profileData1?.banner != null ? (
                // <Stack alignItems={"center"} sx={sx.brandWrapper}>
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
                  {/* <img src={profileData1?.banner} width={70}></img> */}
                  <img
                    src={profileData1?.banner}
                    alt="banner"
                    style={{
                      width: "100px",
                      // height: "70px",
                      // height: "auto",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : (profileData1?.banner == "\\x" ||
                  profileData1?.banner == null ||
                  !profileData1?.banner) &&
                profileData1?.logo ? (
                <Stack alignItems={"center"} sx={sx.brandWrapper}>
                  {/* <img src={profileData1?.logo} width={70}></img> */}
                  <img
                    src={profileData1?.logo}
                    alt="logo"
                    style={{
                      width: "100px",
                      // height: "70px",
                      height: "50px",
                      objectFit: "contain",
                    }}
                  ></img>
                </Stack>
              ) : null
            ) : null}
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <Box sx={sx.iconWrapper}>
              <Tooltip title="Search">
                <IconButton
                  onClick={() => {
                    logBasicSearchUsageData(8);
                    navigate("/search");
                  }}
                >
                  <SearchOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Search History">
                <IconButton
                  onClick={() => {
                    logBasicSearchUsageData(96);
                    navigate("/searchhistory");
                  }}
                >
                  <AccessTimeOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Bookmarked Articles">
                <IconButton
                  onClick={() => navigate("/marked-result")}
                  sx={{ fontSize: "14px" }}
                >
                  <BookmarkBorderOutlinedIcon
                    sx={{
                      color: markedResult.length ? sx.markedView : "",
                    }}
                  />{" "}
                  {markedResult.length ? `(${markedResult?.length})` : ""}
                </IconButton>
              </Tooltip>
            </Box>

            <Stack
              direction={"row"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <Text>
                Hi {me?.firstname} {me?.lastname}
              </Text>
              <Avatar
                sx={{
                  fontSize: "12px",
                  ml: 2,
                  bgcolor: "primary.main",
                  color: "#FFF",
                }}
                alt={me?.firstname}
                src={me?.profileuserimagepath}
              >
                {Array.isArray(me?.firstname) ? me?.firstname[0] : ""}
                {Array.isArray(me?.lastname) ? me?.lastname[0] : ""}
              </Avatar>
              <ArrowDropDownOutlinedIcon />
            </Stack>
          </Stack>

          {/* User Profile Menu */}
          <ProfileMenu
            anchorEl={anchorEl}
            open={open}
            closeMenu={closeMenu}
            profileDetail={me}
          />
        </Toolbar>
      </AppBar>

      {/* Nav Drawer */}
      <SideDrawer
        toggleNav={toggleNav}
        drawerRef={drawerRef}
        closeNav={closeNav}
      />
    </>
  );
};

export default MainNav;
