import Grid from "@mui/material/Grid";
import Box, { BoxProps } from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Colors } from "../utils/constants";

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        mt: 3,
        width: 450,
        textAlign: "left",
        ...sx,
      }}
      {...other}
    />
  );
}

const ContactUs = () => {
  return (
    <>
      <Box marginLeft={"70px"} marginTop={"53px"}>
        <Typography fontWeight={700} fontSize={28} sx={{ color: "#7852fb" }}>
          Head Office
        </Typography>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6} md={4}>
            <Item>
              <Typography sx={textStyle.heading}>
                Informatics India Ltd
              </Typography>
              <Typography
                color={Colors.coolGray700}
                fontSize={"18px"}
                fontWeight={500}
              >
                No 194, R V Road, P B No 400 Basavanagudi
                <br /> Bangalore - 560 004
              </Typography>
            </Item>
            <Item>
              <Typography sx={textStyle.heading}>Phone</Typography>
              <Typography sx={textStyle.text}>+91 80 40387777</Typography>
            </Item>
            <Item>
              <Typography sx={textStyle.heading}>Fax</Typography>
              <Typography sx={textStyle.text}>+91 80 40387600</Typography>
            </Item>
            <Item>
              <Typography sx={textStyle.heading}>e-mail</Typography>
              <Typography sx={textStyle.text}>
                jcoordinator@informaticsglobal.com
              </Typography>
            </Item>
          </Grid>
          {/* for DF-1539 */}
          {/* <Grid item xs={6} md={8}>
                        <Item>
                            <Typography sx={textStyle.heading}>Product Support</Typography>
                        </Item>

                        <Item>
                            <Typography sx={textStyle.heading}>Phone</Typography>
                            <Typography sx={textStyle.text}>+91 80 40387777</Typography>
                        </Item>
                        <Item>
                            <Typography>Fax</Typography>
                            <Typography sx={textStyle.text}>+91 80 40387600</Typography>
                        </Item>
                        <Item>
                            <Typography sx={textStyle.heading}>e-mail</Typography>
                            <Typography sx={textStyle.text}>info@informaticsglobal.com</Typography>
                        </Item>
                    </Grid> */}
        </Grid>
      </Box>
    </>
  );
};

export default ContactUs;

const textStyle = {
  heading: {
    fontWeight: 700,
    fontSize: "18px",
    fontFamily: "Helvetica Neue",
  },
  text: {
    fontSize: "18px",
    fontWeight: 500,
    color: Colors.purple800,
  },
};
