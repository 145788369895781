import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { Colors } from "../utils/constants";

interface P {
  dialogueData?: any;
}

const BookDetails: React.FC<P> = ({ dialogueData }) => {
  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        sx={{ width: "95%", marginInline: "auto", marginTop: "15px" }}
        rowGap={1}
        direction={"column"}
      >
        <Grid item>
          {dialogueData?.aboutresource ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              {/* <Box minWidth={"150px"}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: "Helvetica Neue", fontWeight: "700" }}
                >
                  About Book :
                </Typography>
              </Box> */}
              <Typography variant="body2">
                <div
                  dangerouslySetInnerHTML={{
                    __html: dialogueData?.aboutresource,
                  }}
                />
              </Typography>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BookDetails;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "15px",
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",
  },
  informationText: {
    width: "70%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    marginTop: "2%",
  },
};
