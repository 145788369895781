import { queryConstructor } from "../../api/formQuery";
import { basicSearch } from "../../api/Search/BasicSearch.api";
import { InitialstateType as SearchReducerType } from "../../store/slice/authorFinderSlice";
import { InitialState as FilterType } from "../../store/slice/filterSlice";
import { authorFinderApi } from "../../api/authorFinderApi";


export default async function onPageData(
  authorReducer: SearchReducerType,
  //  filters: FilterType,
  // fullText?: boolean
) {
  interface ObjType {
    [key: string]: any;
  }
  let searchKeys = Object.keys(authorReducer);
  let searchQueryObj: ObjType = {};

  if (searchKeys.includes("page")) {
    if(authorReducer.page!=0){
      searchQueryObj.page = authorReducer.page;
    } 
   
  }
  if (searchKeys.includes("rows")) {
    if(authorReducer.rows>10){
      searchQueryObj.rows = authorReducer.rows;
    }
    if(authorReducer.selectedTag === '0-9'){
      searchQueryObj.rows = 10;
    }
  }
  if (searchKeys.includes("firstname")) {
    if(authorReducer.firstname.length>0){
      searchQueryObj.author_firstname_fz = `("${authorReducer.firstname?.toString()?.replaceAll('"', '')}")`
    }
   
  }
  if (searchKeys.includes("lastname")) {
    if(authorReducer.lastname.length>0){
      searchQueryObj.author_lastname = `("${authorReducer.lastname?.toString()?.replaceAll('"', '')}")`;
    }
   
  }
  if (searchKeys.includes("dataType")) {
    if(authorReducer.dataType.length>0){
      searchQueryObj.fq__resourcetype_id = authorReducer.dataType;
    }
  }

  if (searchKeys.includes("subjectType")) {
    if(authorReducer.subjectType.length>0){
      searchQueryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
    }
  }

  if (searchKeys.includes("subjectType")) {
    if(authorReducer.subjectType.length>0){
      searchQueryObj.fq__datsubjectmasterlevel2_id = authorReducer.subjectType;
    }
  }
  if (searchKeys.includes("institutions")) {
    if(authorReducer.institutions.length>0){
      searchQueryObj.author_affiliation = authorReducer.institutions;
    }
  }
  if (searchKeys.includes("sort")) {
    if(authorReducer.sort!=''){
      let fname_text = 'author_firstname';
      let lname_text = 'author_lastname';
      let sort_text = authorReducer.sort;
      let result = fname_text.concat(" ", sort_text, ",", " ", lname_text, " ", sort_text);
      searchQueryObj.sort = result;
    }
   
  }
  if (searchKeys.includes("selectedTag")) {
    if(authorReducer.selectedTag!=='ALL' && authorReducer.selectedTag!=='0-9' && authorReducer.selectedTag!==""){
      searchQueryObj.author_name_initial = authorReducer.selectedTag;
    }
  }

let searchQuery = await queryConstructor(searchQueryObj);

let query = searchQuery;

let data = await authorFinderApi(query);

return data;
}