import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  BoxProps,
} from "@mui/material";
import { Colors } from "../../utils/constants";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { AuthorFinderObj, ReducerObj } from "../../types/interface";
// import search from "../../screens/AuthorFinder/onAuthorResult";
// import search from "../../screens/AuthorFinder/onAuthorSearch";
// import search from '../../screens/Search/onSearch';
// import { CustomTooltip } from "../MyLibrary/MyAlerts/Alerts.style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";

import {
  clearMarkedResult,
  clearSearchTerm,
  removeSearchTerm,
  sliceSearchTerm,
  updateMarkedArticleCount,
  updateNewSearchStatus,
  updatePage,
  updateSearchResults,
  updateSearchTerm,
} from "../../store/slice/searchSlice";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  removeAllFilterType,
  updateAuthor,
  updateCountryOfPublication,
  updateCountryOfPublishingAuthor,
  updateInstitution,
  updateJournal,
  updateJournalRank,
  updateLoadingState,
  updatePublisher,
  updateResearch,
  updateSourceType,
  updateSpeaker,
  updateSubject,
  updateYearFrom,
} from "../../store/slice/filterSlice";
import {
  clearAuthorDataTypeFilters,
  clearAuthorSearchTerm,
  removeAuthorSearchTerm,
  sliceAuthorSearchTerm,
  updateAbastractAuthor,
  updateAuthorDataType,
  updateAuthorFullText,
  updateAuthorInitial,
  updateAuthorNewSearchStatus,
  updateAuthorPage,
  updateAuthorRow,
  updateAuthorSearchResults,
  updateAuthorSearchTerm,
  updateAuthorSelectedTag,
  updateAuthorSubjectType,
  updateIsAuthorAnalytics,
  updateIsAuthorSettled,
} from "../../store/slice/authorFinderSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useGetMySearchPreference } from "../../api/Preferences/Subjects.api";
import { isPreferenceAdded, showIsProfileToast } from "../../utils/helper";
import { setModal } from "../../store/slice/modalSlice";
import { GetMyPreferenceData } from "../../types/GetMySubjects.types";
import { CustomTooltip } from "../../screens/MyLibrary/MyAlerts/Alerts.style";
import { usagelogApi } from "../../api/usageReportApi";

const x = [
  "Machine Cubes",
  "General Electric",
  "CT and MRI",
  "Marching Squares",
];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 19,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 18,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#31C48D",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 15,
    height: 15,
    borderRadius: 10,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

interface Proptype {
  totalResult?: string | number;
}

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        ...sx,
      }}
      {...other}
    />
  );
}

const AuthorFinderTopbar = (props: Proptype) => {
  const navigate = useNavigate();

  const searchReducer: any = useSelector(
    (state: ReducerObj) => state.searchReducer
  );

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const selectedFilters: any = useSelector(
    (state: ReducerObj) => state.filterReducer
  );

  const customerData = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const filterReducer: any = useSelector(
    (state: ReducerObj) => state.filterReducer
  );

  const allAppliedFilter = useAppSelector(
    (state) => state.filterReducer.allAppliedFilter
  );

  const user_id = useAppSelector((state) => state.login.user_id);

  const dispatch = useAppDispatch();

  // const searchTermvalue = getParamsByName("searchterm");

  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");

  const isPreferenceEnabled = sessionStorage.getItem("preference") === "true";

  const [isPreference, setIsPreference] = useState(isPreferenceEnabled);

  const trigger = useRef(0);
  const triggerPreference = useRef(0);

  //Get Personalized Data for Preference
  const { data: myPreference, refetch } = useGetMySearchPreference(
    user_id,
    isPreference
  );

  useEffect(() => {
    refetch();
  }, []);

  // useEffect(() => {
  //     isPreference && applyPreference();
  // }, [isPreference, myPreference]);

  useEffect(() => {
    triggerPreference.current && applyPreference();
  }, [isPreference, myPreference]);

  function searchTermChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value) setError("");
    setSearchTerm(e.target.value);
  }

  useEffect(() => {
    if (trigger.current) onAuthorSearch();
  }, [trigger.current]);

  useEffect(() => {
    onRefineSearch();
  }, []);

  function onRefineSearch() {
    if (!searchTerm) {
      return;
    }

    dispatch(updateAuthorSearchTerm(searchTerm));

    dispatch(updateAuthorPage(1));
    // setValue("searchterm", "");
    setSearchTerm("");
    trigger.current = trigger.current + 1;
  }

  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logAuthorResultUsageData = (usageActionID: any, data?: any) => {
    const articleArticle_Id = data?.article_id ? data?.article_id : null;
    const articleResourcemaster_id = data?.resourcemaster_id
      ? data?.resourcemaster_id
      : null;
    const articleResourceissue_id = data?.resourceissue_id
      ? data?.resourceissue_id
      : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      null,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  async function onNewSearch() {
    dispatch(clearAllFiltersValue());
    dispatch(updateLoadingState(true));
    dispatch(updateAuthorFullText(true));

    // dispatch(updateAuthorSelectedTag("*"))
    dispatch(clearAuthorSearchTerm());
    dispatch(updateAuthorSearchTerm(searchTerm));

    dispatch(clearSearchTerm());
    dispatch(clearAllFilters());
    // dispatch(clearMarkedResult());
    dispatch(clearPublicationFilter());
    dispatch(updateSearchTerm(searchTerm));
    dispatch(updateNewSearchStatus(true));

    dispatch(updateAuthorPage(1));
    dispatch(updateAuthorRow(15));
    dispatch(updateAbastractAuthor(false));
    dispatch(updateIsAuthorAnalytics(false));

    navigate("/authorfinder");
    setSearchTerm("");
    dispatch(updateAuthorSelectedTag("*"));
    dispatch(updateAuthorInitial("*"));
    // trigger.current = trigger.current + 1;
    await dispatch(search());
    dispatch(updateMarkedArticleCount([]));
    dispatch(updateIsAuthorSettled(true));
  }

  function onRemove(value: string) {
    // dispatch(removeSearchTerm(value));

    dispatch(removeAuthorSearchTerm(value));

    const tempValue = filterReducer.author?.filter(
      (data: any) => data !== value
    );

    dispatch(updateAuthor(tempValue));
    //dispatch(removeAuthorSearchTerm(value));
    trigger.current = trigger.current + 1;
  }

  function clearAll() {
    dispatch(sliceAuthorSearchTerm());
    // dispatch(sliceSearchTerm());
    dispatch(clearAllFilters());
    trigger.current = trigger.current + 1;
  }

  // On search
  async function onAuthorSearch() {
    //  dispatch(updateLoadingState(true));
    if (authorReducer.searchTerm.length) {
      dispatch(search());
      // const data = await search(authorReducer, selectedFilters, dispatch);
      // // Dispatch to redux
      // dispatch(updateAuthorSearchResults(data));
    }
    //  dispatch(updateLoadingState(false));

    dispatch(updateAuthorNewSearchStatus(false));
  }

  const removeFilter = (
    value: string,
    arr: string[],
    setterFunction: Function
  ) => {
    let filteredValues = arr.filter((data) => data !== value);
    dispatch(setterFunction(filteredValues));
    // setTriggerCount((prev) => prev + 1);
    // trigger.current = trigger.current + 1;
    // remove data from the allappliedfilter state
    dispatch(removeAllFilterType(value));

    dispatch(search());
  };

  async function checkPreferenceAdded(myPreference: GetMyPreferenceData) {
    if (!myPreference) return;
    const filteredSubjectData = myPreference.Subjects?.map(
      ({ subject2_details }) =>
        subject2_details.filter(({ is_favorite }) => is_favorite)
    ).flat();

    const filteredSelectedTags = myPreference?.DataTypes.filter(
      ({ is_favorite }: any) => is_favorite
    ).map(({ datatype_id }) => datatype_id);
    if (filteredSelectedTags?.filter((ele: any) => ele !== 2)?.length === 0) {
      setIsPreference(false);
      sessionStorage.setItem("preference", "false");
    }
  }

  function handlePreference(event: React.ChangeEvent<HTMLInputElement>) {
    logAuthorResultUsageData(112);
    if (sessionStorage.getItem("isProfileUser") === "true") {
      let isMyPrefAdded: boolean = true;
      if (myPreference) {
        isMyPrefAdded = isPreferenceAdded(myPreference);
      }

      if (!isMyPrefAdded && event.target.checked) {
        dispatch(
          setModal({
            modalType: "PREFERENCE",
            modalProps: {
              open: true,
              checkPreferenceAdded,
            },
          })
        );
      }
      setIsPreference(event.target.checked);
      sessionStorage.setItem("preference", event.target.checked.toString());
    } else {
      showIsProfileToast("Please login as profile user to use the preferences");
    }
  }

  async function applyPreference() {
    if (!user_id || !customerData) return;
    if (!myPreference) return;

    //  dispatch(clearAuthorDataTypeFilters());

    if (isPreference && myPreference) {
      // Update preference data type
      const preferedSources = myPreference.DataTypes.filter(
        (type) => type.is_favorite
      )?.map((source) => source.datatype_id);

      dispatch(updateAuthorDataType(preferedSources));

      // update subject ids
      const preferedSubjects = myPreference.Subjects?.map((l1) =>
        l1.subject2_details.filter((l2) => l2.is_favorite)
      )
        .flat()
        ?.map((l2) => {
          return {
            datsubjectmasterlevel1_id: -1, //Unused, Just for Integrity's Sake
            datsubjectmasterlevel2_id: l2.subject_id2,
            level2subject_name: l2.subject_name2,
          };
        });

      const subjectIds = preferedSubjects?.map(
        (x) => x.datsubjectmasterlevel2_id
      );

      dispatch(updateAuthorSubjectType(subjectIds));
    } else {
      const subjectIds = customerData.subject?.map(
        (x: any) => x?.datsubjectmasterlevel2_id
      );
      const dataTypeIds = ["1", "2", "3", "4", "8", "24"];
      // update datatype ids
      dispatch(updateAuthorDataType(dataTypeIds));

      //Update Subject Ids
      dispatch(updateAuthorSubjectType(subjectIds));
    }
  }

  function toTitleCase(text: string) {
    const words = text.split(" ");
    const titleCasedWords = words?.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titleCasedWords.join(" ");
  }

  return (
    <Box bgcolor={Colors.white} py={"1%"}>
      <Box sx={{ paddingInline: "66px" }}>
        <Box sx={webStyle.flexRow}>
          <Box sx={webStyle.flexRow}>
            <Typography sx={webStyle.textResult} variant="h5">
              Search Results
              {/* ({searchResults?.hits}) */}
            </Typography>
          </Box>
          <Box sx={[webStyle.flexRow, { alignItems: "center" }]}>
            <Typography sx={[webStyle.toogleText]}>Preferences</Typography>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              marginLeft={"6%"}
            >
              <AntSwitch
                checked={isPreference}
                inputProps={{ "aria-label": "ant design" }}
                onChange={handlePreference}
              />
            </Stack>
            <Stack>
              <CustomTooltip
                title={
                  <>
                    <span style={{ fontSize: "14px" }}>
                      Enable & Configure the preferences for Subjects & Document
                      types for customized search.
                    </span>
                    <br />
                    <span style={{ fontSize: "14px" }}>
                      Click on "Preferences" from Profile dropdown for settings
                    </span>
                  </>
                }
                arrow
                placement="top"
              >
                <IconButton size="medium">
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </CustomTooltip>
            </Stack>
          </Box>
        </Box>

        {/* Input box */}
        <Box>
          <Item
            sx={{
              flexShrink: 0,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={[
                webStyle.flexRow,
                {
                  marginTop: "1%",
                  marginBottom: "1%",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: "center",
                },
              ]}
            >
              <Button sx={webStyle.clearallText} onClick={clearAll}>
                Clear All
              </Button>

              {Array.isArray(authorReducer.searchTerm) &&
                authorReducer.searchTerm?.map((val: string, i: number) => {
                  return (
                    <Stack direction="row" spacing={2}>
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() => {
                          if (i > 0) onRemove(val);
                        }}
                      >
                        {/* {val} */}

                        {toTitleCase(
                          val.split(",").reverse().join(" ").replace(",", "")
                        )}
                        {/* {val.length ? val.trim().replace(/^\,+|\,+$/g, '').trim() : ""}{" "} */}
                      </Button>
                    </Stack>
                  );
                })}

              {/* // Filters */}
              {/* SOURCE TYPE */}
              {selectedFilters.sourceType.length
                ? selectedFilters.sourceType?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.sourceType,
                            updateSourceType
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}

              {/* SUBJECT FILTER */}
              {selectedFilters.subject.length
                ? selectedFilters.subject?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.subject,
                            updateSubject
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}

              {/* Author filter */}
              {selectedFilters.author.length
                ? selectedFilters.author?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.author,
                            updateAuthor
                          )
                        }
                      >
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}

              {/* SPEAKER */}
              {selectedFilters.speaker.length
                ? selectedFilters.speaker?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.speaker,
                            updateSpeaker
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}

              {/* Guidek */}
              {selectedFilters.research.length
                ? selectedFilters.research?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.research,
                            updateResearch
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}
              {/* Journal */}
              {selectedFilters.journal.length
                ? selectedFilters.journal?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.journal,
                            updateJournal
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}
              {/* Journal Rank */}
              {selectedFilters.journalRank.length
                ? selectedFilters.journalRank?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.journalRank,
                            updateJournalRank
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}
              {/* Country of Publication */}
              {selectedFilters.countryOfPublication.length
                ? selectedFilters.countryOfPublication?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.countryOfPublication,
                            updateCountryOfPublication
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}
              {/* Country of Publication Author */}
              {selectedFilters.countryOfPublishingAuthor.length
                ? selectedFilters.countryOfPublishingAuthor?.map(
                    (data: string) => (
                      <Stack direction={"row"} alignItems="center">
                        <Button
                          size="small"
                          variant="outlined"
                          endIcon={<CloseIcon />}
                          sx={webStyle.searchTextFont}
                          onClick={() =>
                            removeFilter(
                              data,
                              selectedFilters.countryOfPublishingAuthor,
                              updateCountryOfPublishingAuthor
                            )
                          }
                        >
                          {/* {data} */}
                          {toTitleCase(
                            data.split(",").reverse().join(" ").replace(",", "")
                          )}
                        </Button>
                      </Stack>
                    )
                  )
                : null}

              {/* Publishers */}
              {selectedFilters.publisher.length
                ? selectedFilters.publisher?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.publisher,
                            updatePublisher
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}
              {/* Institutions */}
              {selectedFilters.institutions.length
                ? selectedFilters.institutions?.map((data: string) => (
                    <Stack direction={"row"} alignItems="center">
                      <Button
                        size="small"
                        variant="outlined"
                        endIcon={<CloseIcon />}
                        sx={webStyle.searchTextFont}
                        onClick={() =>
                          removeFilter(
                            data,
                            selectedFilters.institutions,
                            updateInstitution
                          )
                        }
                      >
                        {/* {data} */}
                        {toTitleCase(
                          data.split(",").reverse().join(" ").replace(",", "")
                        )}
                      </Button>
                    </Stack>
                  ))
                : null}
              {selectedFilters.yearFrom.length ? (
                <Stack direction={"row"} alignItems="center">
                  <Button
                    size="small"
                    variant="outlined"
                    endIcon={<CloseIcon />}
                    sx={webStyle.searchTextFont}
                    onClick={() =>
                      removeFilter(
                        Array.isArray(selectedFilters.yearFrom)
                          ? selectedFilters.yearFrom[0]
                          : selectedFilters.yearFrom,
                        Array.isArray(selectedFilters.yearFrom)
                          ? selectedFilters.yearFrom
                          : [selectedFilters.yearFrom],
                        updateYearFrom
                      )
                    }
                  >
                    {/* {data} */}
                    {`Publication date:${selectedFilters.yearFrom}`}
                  </Button>
                </Stack>
              ) : null}
            </Box>

            <Button
              variant="outlined"
              sx={webStyle.button}
              onClick={onNewSearch}
            >
              New Author Search
            </Button>
          </Item>
        </Box>

        {/* bottom text */}
        {/* @ts-ignore */}
      </Box>
    </Box>
  );
};

export default AuthorFinderTopbar;

const webStyle = {
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
  },
  toogleText: {
    fontFamily: "Inter",
    fontWeight: "500",
    marginLeft: "20%",
  },
  clearallText: {
    height: 22,
  },
  searchTextContainer: {
    marginLeft: "1%",
    border: 1,
    borderColor: Colors.gray_400,
    borderRadius: 1,
  },
  searchText: {
    // padding:"0.1%"
    // padding:0.5
  },
  searchTextFont: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: Colors.gray900,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: Colors.gray_400,
    marginLeft: 1,
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 22,
    borderRadius: "6px",
    //  fontSize:"0.875rem"
  },
  button: {
    marginLeft: "0.9%",
    padding: "0.7rem",
    width: "12%",
    fontWeight: 500,
    fontSize: "16px",
    minWidth: "189px",
    height: "51px",
  },
  inputField: {
    width: "77%",
    marginRight: "1%",
    borderRadius: 60,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.coolGray300,
        borderRadius: 2,
      },
    },
    fontFamily: "Helvetica Neue",
    fontSize: "0.875rem",
  },
};
