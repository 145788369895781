import { Button, Tooltip } from "@mui/material";
import Stack from "@mui/material/Stack";
import { usageReport, usagelogApi } from "../../api/usageReportApi";
import GreenOpenLock from "../../assets/images/open-green-lock.svg";
import { useAppSelector } from "../../store/hooks";

const DownloadButton = (props: any) => {
  const { link, articleData, resultFrom } = props;
  const fullText = useAppSelector((state) => state?.searchReducer?.fullText);
  // console.log("fullText", fullText);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const profileData = useAppSelector((state) => state.login);

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;
  const logUsageAccessButtonData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };
  return (
    <Stack direction="row" spacing={2}>
      <Tooltip
        title={
          articleData?.data_type === "Pre-Print"
            ? "Preprint version"
            : "Open Access Article"
        }
      >
        <Button
          color="secondary"
          variant="outlined"
          href={link && link}
          target="_blank"
          rel="noopener noreferrer"
          download={"true"}
          startIcon={<img src={GreenOpenLock} />}
          onClick={
            articleData?.data_type === "Pre-Print" && fullText
              ? () => logUsageAccessButtonData(35, articleData)
              : () => logUsageAccessButtonData(32, articleData)
          }
        >
          Access Full Text
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default DownloadButton;

const webStyles = {};
