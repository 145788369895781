export async function queryConstructor(queryObj: any, condition = "OR", removeQutation=false) {
  const ret = [];

  // removeQutation -> filter work with string with qutaion and the search and refine search not required
  for (let d in queryObj) {
    if (typeof queryObj[d] === "object") {
      let flag = 0;
      for (let arrD in queryObj[d]) {
        if (queryObj[d].length > 1 && flag === 0) {
          let val = removeQutation ? queryObj[d]?.map((data: any) => `(${data})`) : queryObj[d]?.map((data: any) => `"${data}"`);
          val.join(" OR ");
          let qstr = val.join(` ${condition} `);
          let encodedquery = encodeURIComponent(qstr);
          let newQueryString = `(${encodedquery})`;

          ret.push(`${encodeURIComponent(d)}=${newQueryString}`);
          flag = 1;
        } else if (queryObj[d].length == 1) {
          if (d === "titleKeywordsAbs") {
            ret.push(
              `${encodeURIComponent(d)}=${encodeURIComponent(
                `${queryObj[d][arrD]}`
              )}`
            );
          } else {
            ret.push(
              `${encodeURIComponent(d)}=${encodeURIComponent(
                `"${queryObj[d][arrD]}"`
              )}`
            );
          }
        }
      }
    } else if (typeof queryObj[d] === "undefined") {
      ret.push(encodeURIComponent(d));
    } else {
      ret.push(`${encodeURIComponent(d)}=${encodeURIComponent(queryObj[d])}`);
    }
  }
  let query = ret.join("&");
  return query;
}
