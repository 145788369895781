import { Controller } from "react-hook-form";
import {
  Typography as Text,
  Card,
  CardContent,
  Grid,
  FormLabel,
  MenuItem,
  Button,
  Checkbox,
  Select,
  TextField,
  Avatar,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import CustomTextfield from "../../components/formInputs/CustomTextfield";
import { PatternFormat } from "react-number-format";
import DeleteAlertModal from "./DeleteAlertModal";
import useProfile from "./useProfile.hook";

export type ProfileInputs = {
  firstname: string;
  lastname: string;
  emailid: string;
  mobile: string;
  department: string;
  areaofinterest: string;
  libraryidnumber: string;
  userType: string;
  orcid?: string;
  "other-type"?: string;
  datprofiletype_id?: string;
  usermaster_id?: string;
  profile_type_id?: string;
  designation?: string | null;
};

const defaultUserTypes = [
  "Student",
  "Researcher",
  "Faculty",
  "Scientist",
  "Librarian",
  "Others",
];

const Profile = () => {
  const {
    handleSubmit,
    control,
    errors,
    isEdit,
    isAuthor,
    setIsAuthor,
    openViewModal,
    handleOpenViewModal,
    handleCloseViewModal,
    handleEditClick,
    handleFileChange,
    onSubmit,
    imageURL,
    setImageURL,
  } = useProfile();

  return (
    <Box
      sx={{
        width: "100%",
        px: 7,
        py: 2,
        backgroundColor: "#F9FAFB",
        minHeight: "100vh",
      }}
    >
      <Text
        sx={{
          fontSize: "1.5rem",
          fontWeight: 700,
          fontFamily: "Lora",
          my: 3,
        }}
      >
        My Profile
      </Text>

      <Card>
        <CardContent>
          <Stack direction={"row"} spacing={2} alignItems="center">
            <Avatar
              src={imageURL}
              alt="JGate User"
              variant="rounded"
              sx={{
                height: {
                  xs: 91,
                  lg: 182,
                },
                width: {
                  xs: 91,
                  lg: 182,
                },
              }}
            />
            {isEdit ? (
              <Stack
                direction={{ xs: "column", sm: "row", md: "row", lg: "row" }}
                spacing={2}
              >
                <Button variant="outlined" component="label">
                  Choose Picture
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
                <Button
                  variant="outlined"
                  sx={{ border: "1px solid #6B7280", color: "#6B7280" }}
                  onClick={() => setImageURL("")}
                >
                  Remove Picture
                </Button>
              </Stack>
            ) : (
              <Box>
                <Text>JGate ID</Text>
                <TextField disabled size="medium" value={"1245-12465-54"} />
              </Box>
            )}
          </Stack>

          {/* Form */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} sx={{ marginY: 2 }}>
              <Grid item lg={6}>
                <Stack
                  direction={{
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                  }}
                  spacing={2}
                >
                  <Box width={{ xs: "100%", lg: "50%" }}>
                    <FormLabel required>First Name</FormLabel>
                    <CustomTextfield
                      control={control}
                      name="firstname"
                      isEdit={isEdit}
                      placeholder={"Enter your first name"}
                      type="text"
                      required={true}
                    />
                  </Box>

                  <Box width={{ xs: "100%", lg: "50%" }}>
                    <FormLabel required>Last Name</FormLabel>

                    <CustomTextfield
                      control={control}
                      name="lastname"
                      isEdit={isEdit}
                      placeholder={"Enter your last name"}
                      type="text"
                      required={true}
                    />
                  </Box>
                </Stack>

                <Box sx={{ marginY: 2 }}>
                  <FormLabel required>Email ID</FormLabel>
                  <CustomTextfield
                    control={control}
                    name="emailid"
                    //isEdit={isEdit}
                    placeholder={"Enter your email id"}
                    type="text"
                    required={true}
                  />
                </Box>

                <Box sx={{ marginY: 2 }} className="focus-visible">
                  <FormLabel required>Mobile Number</FormLabel>
                  <br />
                  <Controller
                    control={control}
                    name="mobile"
                    render={({ field: { onChange, value } }) => (
                      <PatternFormat
                        style={{
                          width: "100%",
                          padding: "16.5px 14px",
                          backgroundColor: "#FFF",
                          borderRadius: "8px",
                          border: errors.mobile
                            ? "1px solid red"
                            : "1px solid #D1D5DB",
                          fontFamily: "Helvetica Neue",
                        }}
                        placeholder={"Enter your phone number"}
                        type="tel"
                        format="##########"
                        required={true}
                        disabled={!isEdit}
                        value={value}
                        onValueChange={(v) => onChange(v.formattedValue)}
                      />
                    )}
                  />
                </Box>

                <Box sx={{ marginY: 2 }}>
                  <FormLabel required>Department</FormLabel>
                  <CustomTextfield
                    control={control}
                    name="department"
                    isEdit={isEdit}
                    placeholder={"Enter your department"}
                    type="text"
                    required={true}
                  />
                </Box>

                <Box sx={{ marginY: 2 }}>
                  <FormLabel required>User Type</FormLabel>
                  <Controller
                    name={"profile_type_id"}
                    control={control}
                    defaultValue="1"
                    render={({ field: { onChange, value } }) => {
                      console.log(value);

                      return (
                        <>
                          <Select
                            fullWidth
                            disabled={!isEdit}
                            onChange={(e) => {
                              console.log(e.target.value);
                              onChange(e.target.value);
                            }}
                            defaultValue={value}
                            value={value}
                            displayEmpty
                          >
                            {defaultUserTypes?.map((type, i) => (
                              <MenuItem key={type} value={`${i + 1}`}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                          {value === "6" && (
                            <Box my={1}>
                              <CustomTextfield
                                control={control}
                                name="designation"
                                isEdit={isEdit}
                                required
                              />
                            </Box>
                          )}
                        </>
                      );
                    }}
                  />
                </Box>

                <Box sx={{ marginY: 2 }}>
                  <FormLabel>Area of Interest</FormLabel>
                  <CustomTextfield
                    control={control}
                    name="areaofinterest"
                    isEdit={isEdit}
                  />
                </Box>

                <Box sx={{ marginY: 2 }}>
                  <FormLabel>Library ID</FormLabel>
                  <CustomTextfield
                    control={control}
                    name="libraryidnumber"
                    isEdit={isEdit}
                  />
                </Box>

                {isEdit && (
                  <Stack direction={"row"} alignItems="center">
                    <Text fontFamily="Lora" fontWeight="600">
                      Are you an Author?
                    </Text>
                    <Checkbox
                      checked={isAuthor}
                      onChange={() => setIsAuthor(!isAuthor)}
                      disabled={!isEdit}
                    />
                  </Stack>
                )}

                {isAuthor && isEdit && (
                  <Box sx={{ marginY: 2 }}>
                    <FormLabel>ORCID ID</FormLabel>
                    <CustomTextfield
                      control={control}
                      name="orcid"
                      isEdit={isEdit}
                    />
                  </Box>
                )}
                {!isEdit && (
                  <Box sx={{ marginY: 2 }}>
                    <FormLabel>ORCID ID</FormLabel>
                    <CustomTextfield
                      control={control}
                      name="orcid"
                      isEdit={isEdit}
                    />
                  </Box>
                )}

                <Box marginY={2} />

                {isEdit ? (
                  <Stack
                    direction={{
                      xs: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                    }}
                    spacing={2}
                  >
                    <Button type="submit" variant="outlined">
                      Save Changes
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ border: "1px solid #F05252", color: "#F05252" }}
                      onClick={handleOpenViewModal}
                    >
                      Delete Profile
                    </Button>
                  </Stack>
                ) : (
                  <Button onClick={handleEditClick} variant="outlined">
                    Edit Profile
                  </Button>
                )}
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <DeleteAlertModal
        openViewModal={openViewModal}
        handleCloseViewModal={handleCloseViewModal}
      />
    </Box>
  );
};

export default Profile;
