import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Controller, Control, FieldValues } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface PropType {
  name: string;
  control: Control<FieldValues, any>;
  placeholder?: string;
  type?: string;
  rules?: {};
  fullWidth?: boolean;
  disabled?: boolean;
  inputProps?: any;
  size?: "small" | "medium" | undefined;
  showeyeIcon?: boolean;
  eyeIconStatus?: boolean;
  handleIcon?: () => void;
  onKeyPress?: (e: any) => void;
  inputValue?: any;
}

const Text = (props: PropType) => {
  const {
    name,
    control,
    placeholder,
    type,
    rules,
    fullWidth,
    disabled,
    inputProps,
    size = undefined,
    showeyeIcon = false,
    eyeIconStatus = false,
    handleIcon,
    onKeyPress,
    inputValue,
  } = props;
  return (
    <Box>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({
          field: { onChange, value },
          formState,
          fieldState: { error },
        }) => {
          return (
            <>
              <TextField
                // value={value}
                value={inputValue?.length > 0 ? inputValue : value}
                onChange={onChange}
                placeholder={placeholder && placeholder}
                type={type && type}
                fullWidth={fullWidth}
                disabled={disabled}
                sx={{
                  backgroundColor: "#FFF",
                  border: error ? "1px solid red" : "inherit",
                }}
                inputProps={inputProps}
                size={size}
                onKeyPress={onKeyPress}
                InputProps={{
                  endAdornment: (
                    <>
                      {showeyeIcon && (
                        <InputAdornment position="end">
                          {eyeIconStatus ? (
                            <IconButton onClick={handleIcon && handleIcon}>
                              <Visibility />
                            </IconButton>
                          ) : (
                            <IconButton onClick={handleIcon && handleIcon}>
                              <VisibilityOff />
                            </IconButton>
                          )}
                        </InputAdornment>
                      )}
                    </>
                  ),
                }}
              />
              {error ? (
                <>
                  <Typography
                    sx={{ color: "#F05252", position: "absolute" }}
                    fontSize="12px"
                  >
                    {error.message}
                  </Typography>
                  <Box padding={0.5} />
                </>
              ) : null}
            </>
          );
        }}
      />
    </Box>
  );
};

export default Text;
