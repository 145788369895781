import DownloadButton from "../../components/downloadButton/downloadButton";
import { accessTypeButtons } from "../../utils/helper";
import { useAppSelector } from "../../store/hooks";
import RedOpenLock from "../../components/downloadButton/RedlockOpen";
import RedLockClosed from "../../components/downloadButton/RedLockClosed";
import { LinkResolverType } from "../../api/linkResolverApi";

interface AccessLockType {
  data: any;
  linkToDisplay?: LinkResolverType[] | null;
  details?: any;
  resultFrom?: any;
}
export default function AccessLock(props: AccessLockType) {
  const { data, linkToDisplay, resultFrom } = props;
  const customerId = useAppSelector(
    (state) => state.login.informaticscustomer_id
  );

  const filterReducer = useAppSelector((state) => state.filterReducer);

  let greenLockUrl: string;

  if (data?.articledoi) {
    greenLockUrl = `https://doi.org/${data?.articledoi}`;
  }

  function getButtonType(article_id: string) {
    try {
      if (!article_id || !Array.isArray(linkToDisplay)) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === article_id) {
          return obj?.button_to_display;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  function getButtonLink() {
    try {
      if (!data?.article_id || !Array.isArray(linkToDisplay)) return;
      // console.log('||||||||||||||||||||||||||||||',linkToDisplay)
      for (let obj of linkToDisplay) {
        if (obj?.article_id === data?.article_id) {
          return obj?.links[0];
        }
      }
    } catch (err) {
      console.log(err);
      return "";
    }
  }

  const redCloseLockLink = getButtonLink();

  function AccessButton() {
    // if (!customerId) return;
    // if (accessTypeButtons(data, customerId) === "GreenLock") {
    //   return (
    //     <DownloadButton
    //       link={greenLockUrl ? greenLockUrl : getButtonLink()}
    //       articleData={data}
    //     />
    //   );
    // }
    if (getButtonType(data?.article_id)?.includes("green_open")) {
      return (
        <DownloadButton
          link={getButtonLink()}
          articleData={data}
          resultFrom={resultFrom}
        />
      );
    }
    if (getButtonType(data?.article_id)?.includes("red_open_lock")) {
      return (
        <RedOpenLock
          link={redCloseLockLink}
          articleData={data}
          resultFrom={resultFrom}
        />
      );
    }
    if (
      getButtonType(data?.article_id)?.includes("red_close_lock") &&
      !filterReducer.myLibraryCollection &&
      !filterReducer.consortiaCollection 
    ) {
      return (
        <RedLockClosed
          link={redCloseLockLink}
          articleData={data}
          resultFrom={resultFrom}
        />
      );
    }
    if (accessTypeButtons(data, customerId) === null) {
      return null;
    }
  }

  return <>{AccessButton()}</>;
}
