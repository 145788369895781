import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { alertStyles as styles } from "./Alerts.style";
import { useState } from "react";
import { Colors } from "../../../utils/constants";
import SearchAlerts from "./SearchAlerts";
import AudioVideo from "./AudioVideo";
import PrePrint from "./PrePrint";
import Theses from "./Thesis";
import { a11yProps, TabPanel } from "../../../components/Tabs/TabPanel";
import Manage from "./ManageTOC";
import Add from "./AddTOC";

const Alerts = () => {
  const [alertType, setAlertType] = useState(0);
  const [value, setValue] = useState(0);

  const handleChange = (_: any, newValue: number) => setValue(newValue);

  const handleSelectAlert = (type: number) => setAlertType(type);

  return (
    <>
      {/* Header Tab */}
      <Box sx={styles.selectTextWrapper}>
        <Text
          sx={{
            p: 1,
            backgroundColor: !alertType ? Colors.primary : Colors.white,
            borderRadius: "0.5rem 0 0 0.5rem",
            color: !alertType ? Colors.white : Colors.black,
          }}
          onClick={() => handleSelectAlert(0)}
        >
          TOC Alerts
        </Text>
        <Text
          sx={{
            p: 1,
            backgroundColor: alertType ? Colors.primary : Colors.white,
            borderRadius: "0 0.5rem 0.5rem 0",
            color: alertType ? Colors.white : Colors.black,
          }}
          onClick={() => handleSelectAlert(1)}
        >
          Subject Alerts
        </Text>
      </Box>

      {/* TOC Alert */}
      {alertType === 0 && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Add" {...a11yProps(0)} />
              <Tab label="Manage" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Add />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Manage />
          </TabPanel>
        </Box>
      )}

      {/* Subject Alert */}
      {alertType === 1 && (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Search Alerts" {...a11yProps(0)} />
              <Tab label="Theses" {...a11yProps(1)} />
              <Tab label="Audio & Video" {...a11yProps(2)} />
              <Tab label="Pre-Print" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <SearchAlerts />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Theses />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <AudioVideo />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <PrePrint />
          </TabPanel>
        </Box>
      )}
    </>
  );
};

export default Alerts;
