import { Control, UseFieldArrayRemove } from "react-hook-form";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import Text from "@mui/material/Typography";
import { Stack } from "@mui/system";
import {
  SelectField,
  TextField as CustomTextField,
} from "../../components/formInputs";
import { queryBuilderStyles as sx } from "./QueryBuilder.styles";
import PlusIcon from "@mui/icons-material/AddCircleOutline";
import TrashIcon from "../../assets/img/trash-icon.png";
import { SelectChangeEvent } from "@mui/material/Select";
import { AppendType, QBFormValues } from "../../hooks/useQBFormField";
import { useState } from "react";
import CustomInputSearch from "./CustomInputSearchHistory";

const conditonData: string[] = ["AND", "OR", "NOT"];
const criteriaData: string[] = [
  "Title/Abstract/Keyword",
  "Title",
  // "Keyword only",
  "Title/Keyword",
  "Publication Year",
  "Author",
  "Author Affiliation",
  "ISSN",
  "DOI",
  "Journal",
];

interface QueryBuilderProps {
  setJournalResult: any;
  setJournal: any;
  index: number;
  qbField: any;
  append: AppendType;
  remove: UseFieldArrayRemove;
  control: Control<QBFormValues, any> | any;
  fields: any;
  setIndexOf: any;
  pageIndex: number;
  handleSubmit: any;
  selectedTags: any;
  selectedJournal?: string[];
  setCheckDoi: React.Dispatch<React.SetStateAction<boolean>>;
  checkDoi: boolean;
}

const QueryBuilder = ({
  setJournalResult,
  setJournal,
  index,
  qbField,
  append,
  remove,
  control,
  fields,
  setIndexOf,
  pageIndex,
  handleSubmit,
  selectedTags,
  setCheckDoi,
  checkDoi,
}: QueryBuilderProps) => {
  const [isPublication, setIsPublication] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);

  const addField = () => {
    if (!checkDoi) {
      append({
        qbIndex: index + 1,
        conditionField: "OR",
        criteriaField: "Title/Abstract/Keyword",
        searchTerm: "",
        start: false,
        contain: true,
        journalSelected: [],
        pageIndex: pageIndex,
        selectedJournal: [],
      });
    }
  };

  const deleteField = (currentIndex: number) => remove(currentIndex);
  const criteriaChange = ({
    target,
  }: SelectChangeEvent<HTMLInputElement> | any) => {
    if (target.value === "DOI") {
      setCheckDoi(true);
    } else {
      setCheckDoi(false);
    }
    if (target.value === "Journal") {
      setIndexOf(index);
      setJournal(true);
      setJournalResult();
    }
    target.value === "Author" ? setIsAuthor(true) : setIsAuthor(false);
    target.value === "Publication Year"
      ? setIsPublication(true)
      : setIsPublication(false);
  };

  const conditionChange = (
    e: SelectChangeEvent<HTMLInputElement>,
    name: string
  ) => console.log(e.target.value, fields);
  return (
    <Stack direction={"row"} alignItems={"flex-start"} sx={{ pl: "66px" }}>
      <Stack direction={"column"} marginTop={1} sx={{ gap: 2 }}>
        <Stack
          direction={"row"}
          alignItems={"end"}
          marginTop={1}
          sx={{
            gap: 2,
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
          }}
        >
          {/* Criteria */}
          <Box sx={{ width: { xs: "230px", sm: "300px", lg: "300px" } }}>
            <Text fontWeight="500">Criteria</Text>

            <SelectField
              control={control}
              name={`myFields.${index}.criteriaField`}
              fullWidth
              handleChange={criteriaChange}
            >
              {criteriaData?.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  disabled={checkDoi ? index > 0 : index > 0 && name === "DOI"}
                >
                  <ListItemText
                    primary={name}
                    sx={{ margin: 0 }}
                    onClick={() => {
                      criteriaChange({ target: { value: name } });
                    }}
                  />
                </MenuItem>
              ))}
            </SelectField>
          </Box>

          {/* From To fields */}
          {isPublication && (
            <>
              <Box>
                <Text>From</Text>
                <CustomTextField
                  control={control}
                  name={`myFields.${index}.from`}
                  placeholder="From"
                  inputProps={{ maxLength: 4 }}
                  rules={{ required: "required field" }}
                  onKeyPress={(e) => {
                    if (selectedTags?.length !== 0) {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }
                  }}
                />
              </Box>

              <Box>
                <Text>To</Text>
                <CustomTextField
                  control={control}
                  name={`myFields.${index}.to`}
                  placeholder="To"
                  inputProps={{ maxLength: 4 }}
                  rules={{ required: "required field" }}
                  onKeyPress={(e) => {
                    if (selectedTags?.length !== 0) {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }
                  }}
                />
              </Box>
            </>
          )}
          {isAuthor && (
            <>
              <Box>
                <Text>Last Name</Text>
                <CustomTextField
                  control={control}
                  name={`myFields.${index}.author_lastname`}
                  placeholder="Last Name"
                  onKeyPress={(e) => {
                    if (selectedTags?.length !== 0) {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }
                  }}
                />
              </Box>

              <Box>
                <Text>First Name</Text>
                <CustomTextField
                  control={control}
                  name={`myFields.${index}.author_firstname_fz`}
                  placeholder="First Name"
                  // rules={{ required: "required field" }}
                  onKeyPress={(e) => {
                    if (selectedTags?.length !== 0) {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }
                  }}
                />
              </Box>
            </>
          )}
          {/* Search Term */}
          {!isPublication && !isAuthor ? (
            <Box
              width={600}
              sx={{
                width: { xs: "230px", sm: "300px", md: "300px", lg: "400px" },
              }}
            >
              <Text fontWeight="500" fontFamily={"Helvetica Neue"}>
                Search Term
              </Text>
              <CustomInputSearch
                control={control}
                name={`myFields.${index}.searchTerm`}
                rules={
                  (index + 1) % 2 !== 0 && {
                    required: "Please enter a Search Term",
                  }
                }
                disabled={index > 0 && checkDoi}
                fullWidth
                // placeholder="$$ saved search and/or search term"
                onKeyPress={(e) => {
                  if (selectedTags?.length !== 0) {
                    if (e.key === "Enter") {
                      handleSubmit();
                    }
                  }
                }}
                // inputValue={
                //   fields[index]?.selectedJournal.length > 0
                //     ? fields[index]?.selectedJournal
                //     : ""
                // }
              />
            </Box>
          ) : (
            ""
          )}

          {/* Trash icon */}
          {index >= 2 && (
            <IconButton size="large" onClick={() => deleteField(index)}>
              <img src={TrashIcon} alt="j-gate search builder" />
            </IconButton>
          )}

          {/* Add icon */}
          {index === qbField.length - 1 && (
            <IconButton
              size="large"
              sx={{ paddingInline: "10px" }}
              onClick={addField}
            >
              <PlusIcon color="primary" />
            </IconButton>
          )}
        </Stack>

        {/* Condition */}
        {(index === 0 ||
          (qbField.length > 2 && index !== qbField.length - 1)) && ( // if its the first field or if there are more than 2 fields and its not the last field
          <Box
            sx={{
              width: { xs: "130px", sm: "150px", lg: "150px" },
              marginY: 1,
            }}
          >
            <SelectField
              control={control}
              name={`myFields.${index}.conditionField`}
              fullWidth
              handleChange={conditionChange}
            >
              {conditonData?.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} sx={{ margin: 0 }} />
                </MenuItem>
              ))}
            </SelectField>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default QueryBuilder;
