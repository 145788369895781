import { Box, Card, CardContent, Typography } from "@mui/material";
import Footer from "../components/footer";
import logo from '../assets/img/logo.png';
import { Link } from "react-router-dom";

export default function ThankYouScreen() {
  return (
    <Box sx={{ display: "flex", flex: 1, justifyContent: "space-between", flexDirection: "column", height: "100vh" }}>

      <Box m={1}>
        <img src={logo} />
      </Box>

      {/* ---------------------- card -------------------------------------- */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
      <Card sx={{ height: "200px", width: "600px", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <CardContent>
            <Typography fontSize={20}>
                Thank you for using J-Gate
            </Typography>
            <Typography sx={{opacity: 0.8}}>
                If you wish to login again. <Link to={"/"}>Click here</Link>
            </Typography>
        </CardContent>
      </Card>
      </Box>

      {/* ---------------------- Footer ------------------------------------- */}
      <Box >
        <Footer />
      </Box>
    </Box>
  );
}
