import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { SearchHistory } from "./SearchHistory";
import { usagelogApi } from "../../api/usageReportApi";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  deleteSearchHistory,
  savedSearchAlert,
} from "../../api/searchHistoryapi";
import {
  searchHistorySuccess,
  updateSearchHistoryResults,
} from "../../store/slice/searchHistorySlice";
import { notify } from "../../utils/Notify";
import { showIsProfileToast } from "../../utils/helper";
import { useState } from "react";
import SaveSearchModal from "./SaveSearchModal";
import { checkAlertTopicAvailability } from "../../api/MyLibrary/SearchAlerts.api";

interface Props {
  open: boolean;
  onClose: () => void;
  anchor: HTMLElement | null;
  selectedMenuHistory?: SearchHistory;
  refetch: () => Promise<void>;
}

const SearchHistoryMenu = ({
  open,
  onClose,
  anchor,
  refetch,
  selectedMenuHistory,
}: Props) => {
  const dispatch = useAppDispatch();
  const { user_id, session_key, csrf_token, informaticscustomer_id } =
    useAppSelector((state) => state.login);
  const customerData = useAppSelector(
    ({ customer }) => customer.customerDetails
  );
  const [saveSearchModal, setSaveSearchModal] = useState(false);
  const [alertTopic, setAlertTopic] = useState("");

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = user_id ? user_id : null;
    const informaticscustomerId = informaticscustomer_id
      ? informaticscustomer_id
      : null;
    const consortiamasterId =
      customerData && customerData.consortiamaster_id
        ? customerData.consortiamaster_id
        : null;
    const sessionKey = session_key ? session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      null,
      null,
      null,
      null,
      null,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handleSaveSearchHistory = async () => {
    if (!alertTopic) {
      notify("error", "Alert Topic is required!");
      return;
    }
    logBasicSearchUsageData(94);
    if (sessionStorage.getItem("isProfileUser") === "true") {
      const search_log_ids = selectedMenuHistory?.search_id;
      const usersearchlog_id = search_log_ids;

      const profileuser_id = user_id;
      const is_saved = true;

      // Check the alert topic availability
      const isTopicAvailable = await checkAlertTopicAvailability(alertTopic);
      if (isTopicAvailable === "Alert Topic already exists") {
        notify("error", isTopicAvailable);
        return;
      }

      try {
        const searchhistoryjson = {
          data: [
            {
              usermaster_id: profileuser_id,
              usersearchlog_id: usersearchlog_id,
              search_topic: selectedMenuHistory?.search_type,
              is_saved: is_saved,
              alert_topic: alertTopic ?? "",
            },
          ],
        };

        await savedSearchAlert({
          searchhistoryjson,
          profileuser_id,
        })
          .then((res) => {
            dispatch(updateSearchHistoryResults(res));
            dispatch(searchHistorySuccess(res));
          })
          .then(() => {
            refetch();
            onClose();
            notify("success", "Search Saved");
          });
        setSaveSearchModal(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      showIsProfileToast("Please login as Profile user to save the Search");
    }
  };

  const deleteHistory = async () => {
    const search_log_ids = selectedMenuHistory?.search_id;

    await deleteSearchHistory(search_log_ids, csrf_token).then(() => {
      refetch();
      onClose();
      notify("success", "Search Deleted");
    });
  };

  const closeSaveSearchModal = () => {
    setSaveSearchModal(false);
  };

  const openSaveSearchModal = () => {
    if (sessionStorage.getItem("isProfileUser") !== "true") {
      showIsProfileToast("Please login as Profile user to save the Search");
      return;
    }
    onClose();
    setSaveSearchModal(true);
  };

  const handleAlertTopic = (e: string) => {
    setAlertTopic(e);
  };

  return (
    <>
      <Menu
        open={open}
        anchorEl={anchor}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          style: {
            transform: "translateY(-81px)",
          },
        }}
      >
        <MenuItem
          onClick={openSaveSearchModal}
          disabled={selectedMenuHistory?.is_saved ? true : false}
        >
          Save Search
        </MenuItem>
        <MenuItem sx={{ color: "red" }} onClick={deleteHistory}>
          Delete Search
        </MenuItem>
      </Menu>
      <SaveSearchModal
        open={saveSearchModal}
        headerText="Save the search"
        onClose={closeSaveSearchModal}
        handleSave={handleSaveSearchHistory}
        alertTopic={alertTopic}
        handleAlertTopicChange={handleAlertTopic}
      />
    </>
  );
};

export default SearchHistoryMenu;
