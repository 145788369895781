import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Text from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { SxProps } from "@mui/material/styles";
import ScrollToTop from "../../components/ScrollToTop";
import { useAppDispatch } from "../../store/hooks";
import { updateCollectionValue } from "../../store/slice/filterSlice";

const PersonalLibrary = () => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>();
  const dispatch = useAppDispatch();

  const tabLinks = [
    "/my-library/folders/",
    "/my-library/alerts/",
    "/my-library/searches/",
    "/my-library/links/",
  ];

  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    navigate(tabLinks[newValue]);
  };

  const root: SxProps = {
    width: "100%",
    px: 7,
    py: 2,
    backgroundColor: "#F9FAFB",
    minHeight: "100vh",
  };

  const title: SxProps = {
    my: 4,
    fontWeight: 700,
    fontSize: "1.5rem",
    fontFamily: "Lora",
  };

  const tab: SxProps = {
    fontWeight: 500,
    fontSize: "1.125rem",
    py: 0,
    px: 1,
    "&.Mui-selected": {
      fontWeight: 700,
    },
  };

  useEffect(() => {
    dispatch(updateCollectionValue(""));
  }, []);

  return (
    <Box sx={root} ref={ref}>
      {/* Title */}
      <Text sx={title}>
        {value === 0 ? "Personal Library" : "Personalization"}
      </Text>

      <Box
        className="tabs-container"
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "0.5rem",
        }}
      >
        <Box sx={{ marginBottom: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ pt: 1, pl: 2 }}
          >
            <Tab label="My Folders" sx={tab} />
            <Tab label="Alerts" sx={tab} />
            <Tab label="Saved Search History" sx={tab} />
            <Tab label="External Links" sx={tab} />
          </Tabs>
          <Divider />
        </Box>

        {/* The Tabs render here  */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // alignItems: "flex-end",
            px: 2,
            py: 1,
            width: "100%",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <ScrollToTop
        onClick={() =>
          ref.current?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          })
        }
      />
    </Box>
  );
};

export default PersonalLibrary;
