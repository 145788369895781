import Box from "@mui/material/Box";
import Text from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { hideModal } from "../../../store/slice/modalSlice";
import { modalBaseStyles } from "../RootModal";
import { alertStyles as styles } from "../../../screens/MyLibrary/MyAlerts/Alerts.style";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Colors } from "../../../utils/constants";
import { useEffect, useState } from "react";
import {
  GetUserL2L3Subject,
  GetUserL2L3Subject3Detail,
} from "../../../types/GetUserL2L3Subjects";
import { useMutateUpdateThesisSubjects } from "../../../api/MyLibrary/Thesis.api";
import { useMutateUpdateAVSubjects } from "../../../api/MyLibrary/AV.api";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useMutateUpdatePreprintSubjects } from "../../../api/MyLibrary/Preprints.api";

interface Props {
  show: boolean;
  title: string;
  type: "av" | "thesis" | "preprint";
  l2Subjects: GetUserL2L3Subject[];
  selectedL3: GetUserL2L3Subject3Detail[];
}

const typeMap: {
  [key: string]: string;
} = {
  av: "8",
  thesis: "4",
  preprint: "24",
};

const AddAVThesesAlertModal = ({
  show,
  title,
  type,
  l2Subjects,
  selectedL3,
}: Props) => {
  const dispatch = useAppDispatch();
  const selector = useAppSelector((state) => state.login);

  const thesisHandler = useMutateUpdateThesisSubjects();
  const avHandler = useMutateUpdateAVSubjects();
  const preprintHandler = useMutateUpdatePreprintSubjects();

  const [l3Subjects, setL3Subjects] = useState<GetUserL2L3Subject3Detail[]>([]);
  const [selectedL3Subjects, setSelectedL3Subjects] = useState<
    GetUserL2L3Subject3Detail[]
  >([]);
  const [tab, setTab] = useState<number>();

  useEffect(() => {
    if (l2Subjects.length > 0) {
      setL3Subjects(() => l2Subjects[0].subject3_details);
      setTab(l2Subjects[0].subject_id2);
    }
  }, [l2Subjects]);

  useEffect(() => {
    if (selectedL3.length > 0) {
      setSelectedL3Subjects(() => selectedL3);
    }
  }, [selectedL3]);

  const handleL2Select = (l2: GetUserL2L3Subject) => {
    setL3Subjects(() => l2.subject3_details);
    setTab(l2.subject_id2);
  };

  const handleL3Select = (l3: GetUserL2L3Subject3Detail) => {
    //Remove
    if (selectedL3Subjects.includes(l3)) {
      const filteredArr = selectedL3Subjects.filter(
        (x) => x.subject_id3 !== l3.subject_id3
      );
      setSelectedL3Subjects(() => filteredArr);
    } else {
      //Add
      setSelectedL3Subjects((prev) => [...prev, l3]);
    }
  };

  const handleSubjectAdd = () => {
    const l3Ids = selectedL3Subjects?.map((x) => x.subject_id3);
    const formData = new FormData();
    formData.append("profileuserid", selector.user_id);
    formData.append("subject_level3_ids", l3Ids.join(","));
    formData.append("datatype_id", typeMap[type]);

    type === "thesis"
      ? thesisHandler.mutate({ formData: formData })
      : type === "av"
      ? avHandler.mutate({ formData: formData })
      : preprintHandler.mutate({ formData: formData });

    dispatch(hideModal());
  };

  return (
    <Modal
      open={show}
      onClose={() => dispatch(hideModal())}
      aria-labelledby="AddAVThesesAlertModal-Title"
      aria-describedby="AddAVThesesAlertModal-Description"
    >
      <Box
        sx={{
          ...modalBaseStyles,
          display: "flex",
          flexDirection: "column",
          width: "56vw",
          maxHeight: "77vh",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Text
            id="modal-modal-title"
            sx={{
              fontWeight: 600,
              fontSize: "1.4rem",
              textAlign: "center",
            }}
          >
            {title}
          </Text>
          <IconButton onClick={() => dispatch(hideModal())}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={styles.avThesesBody}>
          <Box sx={styles.list}>
            {l2Subjects?.map((listItem, i) => (
              <Box
                key={i}
                sx={{
                  ...styles.listItem,
                  backgroundColor:
                    tab === listItem.subject_id2
                      ? Colors.coolGray100
                      : "transparent",
                }}
                onClick={() => handleL2Select(listItem)}
              >
                {listItem.subject_name2}
              </Box>
            ))}
          </Box>
          <Box sx={styles.checkboxList}>
            {l3Subjects?.map((l3, i) => (
              <Box
                key={i}
                sx={styles.checkboxListItem}
                onClick={() => handleL3Select(l3)}
              >
                <Checkbox checked={selectedL3Subjects.includes(l3)} />
                <Text>{l3.subject_name3}</Text>
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "100%", my: 1 }}>
          <Button
            variant="contained"
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
            onClick={handleSubjectAdd}
          >
            Add
          </Button>
          <Button
            variant="outlined"
            onClick={() => dispatch(hideModal())}
            sx={{ px: "3rem", py: "0.5rem", mr: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddAVThesesAlertModal;
