import { createSlice } from "@reduxjs/toolkit";

interface InitialstateType {
  browserResult: string[];
  alertFav: string[];
  keyWord: string[];
  containStartingWith: string;
  setAlphabet: string;
  searchKeyword: string[];
  getCardDataDetails: any;
  markedArticleId: string[];
  markedResult: any;
}

const initialState: InitialstateType = {
  browserResult: [],
  alertFav: [],
  getCardDataDetails: [],
  keyWord: [],
  containStartingWith: "Starting With",
  setAlphabet: "",
  searchKeyword: [],
  markedArticleId: [],
  markedResult: [],
};

export const browseJournalSearchSlicePublisher = createSlice({
  name: "browseJournalSearchPublisher",
  initialState,
  reducers: {
    publisherSearchResult(state, { payload }) {
      state.browserResult = payload;
    },
    alertsAndFav(state, { payload }) {
      state.alertFav = payload;
    },
    getCardData(state, { payload }) {
      state.getCardDataDetails = payload;
    },
    getSelectedKeyWord(state, { payload }) {
      state.keyWord = [...state.keyWord, payload];
    },
    getContainOrStartingWIth(state, { payload }) {
      state.containStartingWith = payload;
    },
    setKeyword(state, { payload }) {
      state.setAlphabet = payload;
    },
    setSearchKeywordPublisher(state, { payload }) {
      state.searchKeyword = [payload];
      // state.searchKeyword = [...state.searchKeyword, payload];
    },
    updateMarkedArticleId(state, { payload }) {
      state.markedArticleId = payload;
    },
    updateMarkedResult(state, { payload }) {
      state.markedResult = payload;
    },
  },
});

export const {
  publisherSearchResult,
  alertsAndFav,
  getCardData,
  getSelectedKeyWord,
  getContainOrStartingWIth,
  setKeyword,
  setSearchKeywordPublisher,
  updateMarkedArticleId,
  updateMarkedResult,
} = browseJournalSearchSlicePublisher.actions;

export default browseJournalSearchSlicePublisher.reducer;
