import { notify } from "../../utils/Notify";
import http from "../httpService";

export const getAllJournals = async (
  userId: string,
  page: number,
  pageSize: number,
  search: string,
  enableSearch: string,
  collectionData:string
) => {
  let params: any = {
    profileuserid: userId,
    page: page,
    sort: "resource_name_tk asc",
    rows: pageSize,
    fq__fulltext_ACL:collectionData,
  };

  if (enableSearch !== "") {
    params = {
      ...params,
      resource_name_fz: `"${search}"`,
      resource_shortname_fz: `OR(${search})`,
    };
  }
  let apiBody = params + collectionData
  return http.get("/api/indexing/solr/resourcecore", {
    params,
  });
};

export const getAllManageResources = async (
  userId: string,
  page: number,
  pageSize: number,
  search: string,
  enableSearch: string
) => {
  let params: any = {
    fq__profileuser_id: userId,
    fq__active_flag: true,
    // op_mode: "and",
    page: page,
    sort: "resource_name_tk asc",
    rows: pageSize,
  };

  if (enableSearch !== "") {
    params = {
      ...params,
      resource_name_fz: `"${search}"`,
      resource_shortname_fz: `OR(${search})`,
    };
  }

  return http.get("/api/indexing/solr/personalresourcecore", {
    params,
  });
};

export const getAlertSettings = async (
  userId: string,
  // resourcemaster_ids: string[]
  resourceMstarIds: any,
) =>
  http.get(
    "/api/check_resource_alert_setting_status/check_resource_alert_setting_status",
    {
      params: {
        usermaster_id: userId,
        resourcemaster_ids: resourceMstarIds.join(","),
      },
    }
  );

export const getAlertStatus = async (
  userId: string,
  resourcemaster_id: string
) =>
  http.get("/api/get_resource_alert_status/get_resource_alert_status", {
    params: {
      usermaster_id: userId,
      resourcemaster_id: resourcemaster_id,
    },
  });

export const getMyLibResources = async (userId: string) =>
  http.get(
    "/api/get_personal_library_resource_list/get_personal_library_resource_list",
    {
      params: {
        usermaster_id: userId,
      },
    }
  );

export const updateFavorites = async (obj: { formData: FormData }) => {
  try {
    const res = await http.post(
      "/api/mark_resources_favorite/mark_resources_favorite",
      obj?.formData
    );
    console.log(res.data.data[0][0].message);
    if (res.data.data[0][0].message === "limit count exceed") {
      notify("error", res.data.data[0][0].message)
    }
  } catch (err) {
    console.log(err)
  }
}

export const updateRSS = async (obj: { formData: FormData }) =>
  http.post(
    "/api/set_alert_for_resource/set_alert_for_resource",
    obj?.formData
  );
