import http from "./httpService";
import api from "./endpoints";
export async function composeEmailapi(
  message: string,
  email: string,
  name: string,
  id: string,
  searchTerm: any,
  loginId: number
) {
  console.log("uerid", id);
  let userId = sessionStorage.getItem("user_id") || loginId;
  let obj = JSON.stringify(id);
  // {api}/api/ArticleMetadataDownload/ArticleMetadataDownload?loggedin_customer_id=1848&receipient_mail=sri@gmail.com&download_type=3&receipient_name=yeshwanth&remarks=testing&article_details=[{"search_term":"cow","article_id":"700086513"}]&selected_type=2
  try {
    const data = await http.get(
      api.baseApi +
        `/api/ArticleMetadataDownload/ArticleMetadataDownload?loggedin_customer_id=${userId}&article_details=${obj}&receipient_mail=${email}&receipient_name=${name}&selected_type=2&remarks=${message}`
    );
    return data;
  } catch (error) {}
}
